import React, { useEffect, useState, useContext } from 'react'
import { Input, FormGroup, Label, Col, Nav } from 'reactstrap'
import axios from 'axios'
import '../../CSS/NavSide.css'
import MenuCompLihat from './MenuCompLihat'
import { AuthContext } from '../../../../App'
import '../../../GlobalVariable'
import { Redirect, Link } from 'react-router-dom'
import { DataContext } from '../../../../DataContext'
import logo from '../../../Utils/image/logo.png'

const api = global.api

export default function TampilAktaAdmin(props) {
    const [akta, setAkta] = useState([])

    // Memabaca data global dari app js (LOGIN)
    const { state, dispatch } = useContext(AuthContext)
    let { lihatData } = useContext(DataContext)
    // Tampil data
    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-akta/${lihatData}`, config)
            .then(res => {
                setAkta(res.data.values)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    if (state.role !== 3) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <div className='nav-sides'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link className='body-btn' to='/admin/data-perusahaan' >
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link className='body-btn' to='/admin/izin-usaha' >
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/admin/akta" className='body-btn active-background' >
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/admin/pemilik" className='body-btn' >
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/admin/pengurus" className='body-btn' >
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/admin/tenaga-ahli" className='body-btn' >
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/admin/pengalaman" className='body-btn' >
                        <p className='textt'>Pengalaman</p>
                    </Link>
                </Nav>
            </div>
            <div className='bg-kedua'>
                <MenuCompLihat />
                <div className='data-akta'>
                    <h5 className='judul-akta'>Akta Pendirian</h5>
                    {akta.map(akta =>
                        <div>
                            <div key={akta.id_akta}>
                                <FormGroup row>
                                    <Label for="nomor" sm={3}>Nomor</Label>
                                    <Col sm={6}>
                                        <Input type='text' name='nomor' placeholder={akta.nomor} disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="tanggal_surat" sm={3}>Tanggal Surat</Label>
                                    <Col sm={3}>
                                        <Input type="date" value={akta.tanggal_surat} id="textAreaa" disabled />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="notaris" sm={3}>Notaris</Label>
                                    <Col sm={6}>
                                        <Input type='text' name='notaris' placeholder={akta.notaris} disabled />
                                    </Col>
                                </FormGroup>
                            </div>
                            <div className='hasil-akta'>
                                <h5 className='judul-akta'>Akta Perubahan Terakhir</h5>
                                <div key={akta.id_akta}>
                                    <FormGroup row>
                                        <Label for="nomor" sm={3}>Nomor</Label>
                                        <Col sm={6}>
                                            <Input type='text' name='no_perubahan' defaultValue={akta.no_perubahan} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="tanggal_surat" sm={3}>Tanggal Surat</Label>
                                        <Col sm={3}>
                                            <Input type="date" defaultValue={akta.tgl_perubahan} name='tgl_perubahan' placeholder='2022-03-02' id="textAreaa" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="notaris" sm={3}>Notaris</Label>
                                        <Col sm={6}>
                                            <Input type='text' name='notaris_perubahan' defaultValue={akta.notaris_perubahan} disabled />
                                        </Col>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
