import React, { useEffect, useState, useContext } from 'react'
import { Input, Form, FormGroup, Label, Col, Button } from 'reactstrap'
import axios from 'axios'
import NavSide from '../NavSide';
import MenuComp from '../MenuComp';
import { AuthContext } from '../../../App';
import '../../GlobalVariable'
import { Redirect, Link } from 'react-router-dom'

const api = global.api

export default function Akta(props) {
    const [akta, setAkta] = useState({ aktas: [] })

    // Memabaca data global dari app js (LOGIN)
    const { state, dispatch } = useContext(AuthContext)
    // Tampil data
    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-akta/${state.id_user}`, config)
            .then(res => {
                setAkta({
                    aktas: res.data.values
                })
            })
            .catch(e => {
                // console.log(e)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                <div className='data-akta'>
                    <div className='input-akta'>
                        <h5 className='judul-akta'>Akta Pendirian</h5>
                        {akta.aktas.length === 0 ? (
                            // Ketika di submit
                            <font color='red'>Belum Isi</font>
                        ) :
                            (
                                <div>
                                    {akta.aktas.map(akta =>
                                        <div key={akta.id_akta}>
                                            <Form>
                                                <FormGroup row>
                                                    <Label for="nomor" sm={3}>Nomor</Label>
                                                    <Col sm={6}>
                                                        <Input type='text' name='nomor' defaultValue={akta.nomor} disabled/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="tanggal_surat" sm={3}>Tanggal Surat</Label>
                                                    <Col sm={3}>
                                                        <Input type="date" defaultValue={akta.tanggal_surat} name='tanggal_surat' id="textAreaa" disabled />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="notaris" sm={3}>Notaris</Label>
                                                    <Col sm={6}>
                                                        <Input type='text' name='notaris' defaultValue={akta.notaris} disabled/>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    )}
                                </div>
                            )}
                    </div>

                    {akta.aktas.length === 0 ? (
                        // Ketika di submit
                        <div>
                            <div className='hasil-akta'>
                                <h5 className='judul-akta'>Akta Perubahan Terakhir</h5>
                                <font color='red'>Belum Isi</font>
                            </div>
                            <Link className='d-flex flex-row-reverse text-decoration' to='/akta/tambah'>
                                <Button className='btn btn-tambah'>Tambah</Button>{' '}
                            </Link>
                        </div>
                    ) :
                        (
                            <div>
                                {akta.aktas.map(akta =>
                                    <div key={akta.id_akta}>
                                        <div className='hasil-akta'>
                                            <h5 className='judul-akta'>Akta Perubahan Terakhir</h5>
                                            <div>
                                                <FormGroup row>
                                                    <Label for="nomor" sm={3}>Nomor</Label>
                                                    <Col sm={6}>
                                                        <Input type='text' name='no_perubahan' defaultValue={akta.no_perubahan} disabled/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="tanggal_surat" sm={3}>Tanggal Surat</Label>
                                                    <Col sm={3}>
                                                        <Input type="date" defaultValue={akta.tgl_perubahan} name='tgl_perubahan' placeholder='2022-03-02' id="textAreaa" disabled/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="notaris" sm={3}>Notaris</Label>
                                                    <Col sm={6}>
                                                        <Input type='text' name='notaris_perubahan' defaultValue={akta.notaris_perubahan} disabled/>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row-reverse">
                                            <Link to={
                                                {
                                                    pathname: `/akta/edit`,
                                                    state: {
                                                        id_akta: akta.id_akta,
                                                        nomor: akta.nomor,
                                                        tanggal_surat: akta.tanggal_surat,
                                                        notaris: akta.notaris,
                                                        no_perubahaan: akta.no_perubahan,
                                                        tgl_perubahan: akta.tgl_perubahan,
                                                        notaris_perubahan: akta.notaris_perubahan,
                                                    }
                                                }
                                            }>
                                                <Button className='btn btn-save mt-1'>
                                                    <i className="fas fa-edit"></i> Edit
                                                </Button>{' '}
                                            </Link>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        )}
                </div>
            </div >
        </div >
    )
}
