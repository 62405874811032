import React, { useEffect, useState, useContext } from 'react'
import { Table, Button } from 'reactstrap'
import axios from 'axios'
import { AuthContext } from '../../../App'
import { Redirect, Link } from 'react-router-dom'
import MenuComp from '../MenuComp'
import '../../GlobalVariable'
import NavSideAdmin from './NavSideAdmin'
import swal from 'sweetalert'
import qs from 'querystring'
import { DataContext } from '../../../DataContext'
import Trash from '../../Utils/icon/trash.svg'
import Eye from '../../Utils/icon/eye.svg'

const api = global.api

export default function DaftarPengajuan(props) {
    // Membaca data global dari app js (fungsi login)
    const { state, dispatch } = useContext(AuthContext)

    const [user_company, setuser_company] = useState([])

    // Show and Hide radio button
    const [show, setShow] = useState(true)

    // Membaca data dari app js ( lihatData )
    let { lihatData, setLihatdata } = useContext(DataContext)

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)   // yang ditampilkan per satu halaman
    const [itemsPerPage] = useState(5)  // menampilkan 5 item
    // State untuk tunjukan batas halaman
    const [pageNumberLimit] = useState(4) // menampilkan berapa banyak halaman nomor yang akan ditampilkan
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(4) // Batas nomor halaman maksimum
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    const handleClick = (event) => { // targetnya adalah id yang merupakan nomor
        setCurrentPage(Number(event.target.id))
    }

    const pages = [];   // berisi jumlah total halaman
    // data array akan menggunakan loop, data akan dibagi dengan itemsPerPage
    for (let i = 1; i <= Math.ceil(user_company.length / itemsPerPage); i++) {
        pages.push(i);
    }

    // Berapa banyak item yang diinginkan
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFistItem = indexOfLastItem - itemsPerPage;

    // untuk merender nomor halaman
    const renderPageNumbers = pages.map(number => {

        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                // Pada className, jika halaman saat ini sama dengan nomor halaman, maka tambahkan class active, yang lain jangan lakukan apapun
                <li key={number} id={number} onClick={handleClick}
                    className={currentPage === number ? "active" : null}
                >
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })

    // Search Bar
    const [searchTerm, setSearchTerm] = useState('')

    // Otorisasi
    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }

    // Tampung Data company
    const fetchData = () => {
        // params 1 adalah role
        // params 2 adalah rekanan
        axios.get(api + `/auth/api/v1/admin/tampil-rekanan/1/1`, config)
            .then(res => {
                setuser_company(res.data.values)
                setShow(true)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    // Tampung Data Personal
    const fetchDatas = () => {
        // params 1 adalah role
        // params 2 adalah rekanan
        axios.get(api + `/auth/api/v1/admin/tampil-rekanan/2/1`, config)
            .then(res => {
                setuser_company(res.data.values)
                setShow(false)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    // Tampung data untuk ubah data
    const stateDataSetujui = {
        id_company: user_company.id_company,
        rekanan: 3,
        response: '',
        display: 'none',
    }
    const [setujuiData, setSetujuidata] = useState(stateDataSetujui)

    // Ubah data rekanan
    const setujuiRekanan = (idcompany, tglrekanan) => {
        const data = qs.stringify({
            id_company: idcompany,
            rekanan: setujuiData.rekanan,
            tgl_rekanan: tglrekanan
        })

        swal({
            title: "Apakah anda yakin akan menyetujui data rekanan ini?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.post(api + '/ubah-rekanan', data).then(json => {
                    if (json.data.status === 200) {
                        setSetujuidata({
                            response: json.data.values,
                            rekanan: 3,
                            display: 'block'
                        })
                        fetchData();
                    } else {
                        setSetujuidata({
                            response: json.data.values,
                            rekanan: 3,
                            display: 'block'
                        })
                    }
                })
                swal("Berhasil menyetujui rekanan!", {
                    icon: "success",
                });
            } else {
                swal("Gagal menyetujui rekanan!");
            }
        });
    }

    // Tampung data untuk ubah data
    const stateDataTolak = {
        id_company: user_company.id_company,
        rekanan: 2,
        response: '',
        display: 'none',
    }
    const [tolakData, setTolakdata] = useState(stateDataTolak)

    // Ubah data rekanan
    const tolakRekanan = (idcompany, tglrekanan) => {
        const data = qs.stringify({
            id_company: idcompany,
            rekanan: tolakData.rekanan,
            tgl_rekanan: tglrekanan
        })

        swal({
            title: "Apakah anda yakin akan menolak data rekanan ini?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.post(api + '/ubah-rekanan', data).then(json => {
                    if (json.data.status === 200) {
                        setTolakdata({
                            response: json.data.values,
                            rekanan: 2,
                            display: 'block'
                        })
                        fetchData();
                    } else {
                        setTolakdata({
                            response: json.data.values,
                            rekanan: 2,
                            display: 'block'
                        })
                    }
                })
                swal("Berhasil menolak rekanan!", {
                    icon: "success",
                });
            } else {
                swal("Gagal menolak rekanan!");
            }
        });
    }

    // Hapus Rekanan
    const DeleteRekanan = (idcompany) => {
        const data = qs.stringify({
            id_company: idcompany
        })

        swal({
            title: "Apakah anda yakin akan menghapus data ini?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(api + '/auth/api/v1/admin/hapus-rekanan', {
                    data: data,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + state.token
                    }
                }).then(json => {
                    if (json.data.status === 200) {
                        fetchData();
                    }
                })
                swal("Data berhasil dihapus!", {
                    icon: "success",
                });
            } else {
                swal("Data gagal dihapus!");
            }
        });
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        fetchData()
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    if (state.role !== 3) {
        return <Redirect to="/" />
    }

    // Pagination
    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1)

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }

    const handlePrevBtn = () => {
        setCurrentPage(currentPage - 1)

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    // Untuk memberikan tampilan ketika mempunyai banyak data
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextBtn}> &hellip;</li>
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevBtn}> &hellip;</li>
    }

    const currentItems = user_company.filter((user_company) => {
        if (searchTerm === "") {
            return user_company
        } else if (user_company.nama_perusahaan.toLowerCase().includes(searchTerm.toLowerCase())) {
            return user_company
        }
    }).slice(indexOfFistItem, indexOfLastItem)  // Iris data untuk setiap halaman

    return (
        <div>
            <NavSideAdmin />
            <div className='bg-kedua'>
                <MenuComp />
                <div>
                    <Button type="button" className={show === true ? "btn btn-admin-show btn-admin-active" : "btn btn-admin-show btn-admin-nactive"} onClick={() => { fetchData() }} >Company</Button>
                    <Button type="button" className={show === false ? "btn btn-admin-show btn-admin-active" : "btn btn-admin-show btn-admin-nactive"} onClick={() => { fetchDatas() }}>Personal</Button>
                </div>
                <div className='bg-ketiga'>
                    <div className='d-flex flex-row-reverse'>
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" onChange={event => { setSearchTerm(event.target.value) }} />
                        </div>
                    </div>

                    <Table>
                        <thead className='table-header'>
                            <tr>
                                <th>Nama Perusahaan</th>
                                <th>Bidang Usaha</th>
                                <th>Tanggal</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Looping data izin_usaha dengan Map */}
                            {currentItems.map(user_company =>
                                <tr key={user_company.id_company}>
                                    <td className='textTableWrapper'>{user_company.nama_perusahaan} </td>
                                    <td className='textTableWrapper'>{user_company.bidang_usaha} </td>
                                    <td>{user_company.tgl_rekanan}</td>
                                    <td>
                                        <Button className='btn btn-setujui' color='success' onClick={() => setujuiRekanan(user_company.id_company, user_company.tgl_rekanan)}>
                                            Setujui
                                        </Button>
                                        <Button className='btn btn-hapus' color='danger' onClick={() => tolakRekanan(user_company.id_company, user_company.tgl_rekanan)}>
                                            Tolak
                                        </Button>
                                    </td>
                                    <td align="right" className='btnWrapper'>
                                        <Link to='/admin/data-perusahaan' onClick={() => setLihatdata(lihatData = user_company.id_company)}>
                                            <Button className='bg-transparent'>
                                                <img className='ic-eye' src={Eye} alt="eye" />
                                            </Button>
                                        </Link>

                                        <Button className='bg-transparent' onClick={() => DeleteRekanan(user_company.id_company)}>
                                            <img className='ic-trash' src={Trash} alt="Trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <ul className='nav justify-content-end pageNumbers'>
                        <li>
                            <button className='btn-pagination' onClick={handlePrevBtn} disabled={currentPage === pages[0] ? true : false}>
                                <i className="fa-solid fa-angle-left"></i>
                            </button>
                        </li>
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}
                        <li>
                            <button className='btn-pagination' onClick={handleNextBtn} disabled={currentPage === pages[pages.length - 1] ? true : false}>
                                <i className="fa-solid fa-angle-right"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
