import React, { useEffect, useState, useContext } from 'react'
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap'
import axios from 'axios'
import { AuthContext } from '../../../App'
import { Redirect, Link } from 'react-router-dom'
import MenuComp from '../MenuComp'
import NavSide from '../NavSide'
import '../../GlobalVariable'

const api = global.api

export default function DataPerusahaan(props) {
    const [user_company, setuser_company] = useState([])

    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }

    const fetchData = () => {
        axios.get(api + `/auth/api/v1/company/tampil-user-company/${state.id_user}`, config)
            .then(res => {
                setuser_company(res.data.values)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                {user_company.map(user_company =>
                    <Form className='data-perusahaan' key={user_company}>
                        <MenuComp />
                        <FormGroup row >
                            <Label sm={3}>Nama Perusahaan</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.nama_perusahaan} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Tipe Perusahaan</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.tipe_perusahaan} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Label sm={3}>Email</Label>
                            <Col sm={8}>
                                <Input type="email" placeholder={user_company.email} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Label sm={3}>NPWP</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.npwp} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Alamat Lengkap</Label>
                            <Col sm={8}>
                                <Input type="textarea" id='textAreaa' placeholder={user_company.alamat} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Kota</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.kota} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Provinsi</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.provinsi} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Kode Pos</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.kode_pos} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Telephone / HP</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.telepon} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Website</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.website} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3}>Kantor Cabang</Label>
                            <Col sm={8}>
                                <Input type="text" placeholder={user_company.kantor_cabang} disabled />
                            </Col>
                        </FormGroup>
                        <div className="d-flex flex-row-reverse">
                            <Link to={
                                {
                                    pathname: `/data-perusahaan/edit`,
                                    state: {
                                        id_company: user_company.id_company,
                                        nama_perusahaan: user_company.nama_perusahaan,
                                        email: user_company.email,
                                        npwp: user_company.npwp,
                                        bidang_usaha: user_company.bidang_usaha,
                                        tipe_perusahaan: user_company.tipe_perusahaan,
                                        alamat: user_company.alamat,
                                        kota: user_company.kota,
                                        provinsi: user_company.provinsi,
                                        kode_pos: user_company.kode_pos,
                                        telepon: user_company.telepon,
                                        website: user_company.website,
                                        kantor_cabang: user_company.kantor_cabang
                                    }
                                }
                            }>
                                <Button className='btn btn-save mt-1'>
                                    <i className="fas fa-edit"></i>   Edit
                                </Button>{' '}
                            </Link>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    )
}
