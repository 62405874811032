import React from 'react'
import './CSS/NavSide.css'
import { Nav } from 'reactstrap';
import logo from '../Utils/image/logo.png'
import { Link, withRouter } from 'react-router-dom'

const activeTab = (history, path1, path2) => {
    if (history.location.pathname === path1 || history.location.pathname === path2) {
        return {
            "color": "#2E2E38",
            "backgroundColor": "#FFFFFF"
        };
    }
};

const activeTabRekanan = (history, path) => {
    if (history.location.pathname === path) {
        return {
            "width": "100%"
        };
    }
};

const activeTabTextRekanan = (history, path) => {
    if (history.location.pathname === path) {
        return {
            "color": "#2E2E38"
        };
    }
};

function NavSide({ history }) {
    return (
        <div>
            <div className='nav-side'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/data-perusahaan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link to="/data-perusahaan" className='body-btn' style={activeTab(history, "/data-perusahaan", "/data-perusahaan/edit")}>
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link to="/izin-usaha" className='body-btn' style={activeTab(history, "/izin-usaha", "/izin-usaha/tambah")}>
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/akta" className='body-btn' style={activeTab(history, "/akta", "/akta/edit")}>
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/pemilik" className='body-btn' style={activeTab(history, "/pemilik", "/pemilik/tambah")}>
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/pengurus" className='body-btn' style={activeTab(history, "/pengurus", "/pengurus/tambah")}>
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/tenaga-ahli" className='body-btn' style={activeTab(history, "/tenaga-ahli", "/tenaga-ahli/tambah")}>
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/pengalaman" className='body-btn' style={activeTab(history, "/pengalaman", "/pengalaman/tambah")}>
                        <p className='textt'>Pengalaman</p>
                    </Link>
                    <Link to="/rekanan" className='btn-rekanan'>
                        <button className="learn-more">
                            <span className="circle" aria-hidden="true" style={activeTabRekanan(history, "/rekanan")}>
                                <span className="icon arrow"></span>
                            </span>
                            <span className="button-text" style={activeTabTextRekanan(history, "/rekanan")}>Ajukan Rekanan</span>
                        </button>
                    </Link>
                </Nav>
            </div>
        </div>
    )
}

export default withRouter(NavSide);