import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import times from '../Utils/icon/times.svg'
import { Button, Row } from 'reactstrap'
import '../GlobalVariable'

import axios from 'axios'
// import { AuthContext } from '../../App'
const qs = require('querystring')
const api = global.api

const RegisterCompany = (props) => {
  const initialState = {
    username: "",
    password: "",
    // Agar buttonnya bisa di klik
    isSubmitting: false,
    // Jika salah memasukan usename atau password
    errorMessage: null
  }

  // Fungsi ini akan mengubah isi dari initial state
  const [data, setData] = useState(initialState)

  const stateForm = {
    nama_perusahaan: "",
    email: "",
    npwp: "",
    bidang_usaha: "",
    tipe_perusahaan: "",
    alamat: "",
    kota: "",
    provinsi: "",
    kode_pos: "",
    telepon: "",
    website: "",
    kantor_cabang: "",
    username: "",
    password: "",
    confirmPassword: ""
  }

  // Fungsi dari stateForm
  const [dataform, setDataForm] = useState(stateForm)

  const handleInputChange = event => {
    // Ketika form diinputkan data, maka akan merubah data state
    setDataForm({
      ...dataform,
      [event.target.name]: event.target.value
    })
  }

  // Fungsi ketika form di submit
  // Memanggil data username dan password lalu diproses menggunakan axios, menggunakan method post data
  const handleFormSubmit = event => {
    event.preventDefault()
    setData({
      ...data,
      // Jika berhasil submit maka tombolnya akan di non aktifkan
      isSubmitting: true,
      errorMessage: null
    })

    // Akan mengambil dari request body
    const requestBody = {
      nama_perusahaan: dataform.nama_perusahaan,
      email: dataform.email,
      npwp: dataform.npwp,
      bidang_usaha: dataform.bidang_usaha,
      tipe_perusahaan: dataform.tipe_perusahaan,
      alamat: dataform.alamat,
      kota: dataform.kota,
      provinsi: dataform.provinsi,
      kode_pos: dataform.kode_pos,
      telepon: dataform.telepon,
      website: dataform.website,
      kantor_cabang: dataform.kantor_cabang,
      username: dataform.username,
      password: dataform.password
    }

    // Konfigurasi untuk memanggil header di postman
    // konfigurasi x-www-form-urlencoded
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    if (dataform.nama_perusahaan === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Nama perusahaan belum diisi!"
      })
    } else if (dataform.email === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Email belum diisi!"
      })
    } else if (dataform.npwp === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "NPWP belum diisi!"
      })
    } else if (dataform.bidang_usaha === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Bidang Usaha belum diisi!"
      })
    } else if (dataform.username === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Email belum diisi!"
      })
    } else if (dataform.password === "" || null) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Password belum diisi!"
      })
    } else if (dataform.password !== dataform.confirmPassword) {
      setData({
        ...data,
        isSubmiting: false,
        // Kalau Error ganti Message jadi message
        errorMessage: "Password dan Confirm Password tidak sama"
      })
    } else {
      // Panggil axios
      axios.post(api + '/auth/api/v1/register-company', qs.stringify(requestBody), config).then(res => {
        if (res.data.success === true && res.data.isRegistered === false) {
          setData({
            ...data,
            isSubmiting: false,
            // Kalau Error ganti Message jadi message
            errorMessage: "Silahkan cek email untuk melakukan verifikasi !"
          })

          // Jika form sudah di submit, maka halaman akan di reset dan form input jadi kosong
          // setDataForm({
          //   ...dataform,
          //   nama_perusahaan: "",
          //   email: "",
          //   npwp: "",
          //   bidang_usaha: "",
          //   tipe_perusahaan: "",
          //   alamat: "",
          //   kota: "",
          //   provinsi: "",
          //   kode_pos: "",
          //   telepon: "",
          //   website: "",
          //   kantor_cabang: "",
          //   username: "",
          //   password: ""
          // })
          props.history.push("/success-registration")

          // Jika data sudah terdaftar
        } else if (res.data.success === false && res.data.isRegistered === true) {
          setData({
            ...data,
            isSubmiting: false,
            // Kalau Error ganti Message jadi message
            errorMessage: "Email anda belum terverifikasi, silahkan cek email atau spam untuk verifikasi."
          })
        } else {
          setData({
            ...data,
            isSubmiting: false,
            // Kalau Error ganti Message jadi message
            errorMessage: res.data.Message
          })
          props.history.push("/success-registration")
        }
        throw res
      }).then(res => {
        // console.log(res);
      }).catch(e => {
        // console.log(e);
      });
    }
  }

  return (
    <div className="ParentBox">
      <div className="container">
        <div className="register">
          <Link to="/" className="d-flex justify-content-end">
            <img src={times} alt="close" className="btn-close" />
          </Link>
          <h1 className="judul-register">Registration</h1>
          <Link type="button" className="btn btn-company" to="/register-company" >Company</Link>
          <Link type="button" className="btn btn-personal" to="/register-personal" >Personal</Link>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="nama_perusahaan">Nama Perusahaan / <i>Company Name</i></label>
              <input type="text" className="form-control box-register" name='nama_perusahaan' value={dataform.nama_perusahaan} onChange={handleInputChange} />
            </div>
            <Row>
              <div className="form-group col-sm-7">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control box-register" name='email' value={dataform.email} onChange={handleInputChange} />
              </div>
              <div className="form-group col-sm-5">
                <label htmlFor="npwp">NPWP / <i>Taxpayer Identification Number</i></label>
                <input type="text" className="form-control box-register" name='npwp' value={dataform.npwp} onChange={handleInputChange} />
              </div>
            </Row>
            <Row>
              <div className="form-group col-7">
                <label htmlFor="bidang_usaha ">Bidang Usaha / <i>Business Fields</i></label>
                <input type="text" className="form-control box-register" name='bidang_usaha' value={dataform.bidang_usaha} onChange={handleInputChange} />
              </div>
            </Row>
            <div className='bg-form-user'>
              <p className="txt-user-company">USER ACCOUNT</p>
              <Row>
                <div className="form-group col-sm">
                  <label htmlFor="username">Email</label>
                  <input type="email" className="form-control box-register-form-user" name='username' value={dataform.username} onChange={handleInputChange} />
                </div>
                <div className="form-group col-sm">
                  <label htmlFor="password">Password</label>
                  <input type="password" className="form-control box-register-form-user" name='password' value={dataform.password} onChange={handleInputChange} />
                </div>
                <div className="form-group col-sm">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input type="password" className="form-control box-register-form-user" name='confirmPassword' value={dataform.confirmPassword} onChange={handleInputChange} />
                </div>
              </Row>
              <label className="container-checkbox ">
                <input type="checkbox" />
                <span className="checkmark"></span>
                I have read and agree to the terms of service
              </label>
              <div className="d-flex flex-row-reverse">
                <Button type="submit" className="btn btn-register-company mt-2"
                  // Cek dulu
                  // ketika lagi dklik maka tombol di nonaktifkan
                  disabled={data.isSubmitting}>
                  {data.isSubmitting ? (
                    // Ketika di submit
                    "..Loading"
                  ) :
                    (
                      // Ketika ngga di submit
                      "Register"
                    )}
                </Button>
              </div>
            </div>
            {/* Menampilkan Pesan error */}
            {data.errorMessage && (
              <div className="alert alert-danger pesan-register" role="alert">
                {data.errorMessage}
              </div>
            )}
          </form>
          <div className="left-sign" >
            <div className="footer-sign">
              <p className="judul-sign">PT Indah Karya (Persero)</p>
              <p>Email : indahkarya@indahkarya.co.id</p>
              <p>Call : 022 7434093</p>
              <p>© 2021 PT Indah Karya (Persero). All Right Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterCompany;