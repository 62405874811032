import React from 'react';
import { Link } from 'react-router-dom'
import times from '../Utils/icon/times.svg'
import { Row } from 'reactstrap';

const RegisterPersonal = () => {

  return (
    <div className="ParentBox">
      <div className="container">
        <div className="register">
          <Link to="/" className="d-flex justify-content-end">
            <img src={times} alt="close" className="btn-close" />
          </Link>
          <h1 className="judul-register">Registration</h1>
          <Link type="button" className="btn btn-company1" to="/register-company" >Company</Link>
          <Link type="button" className="btn btn-personal1" href="/register-personal">Personal</Link>
          <form>
            <div className="form-group reg-username">
              <label for="exampleInputEmail1 ">Name</label>
              <input type="text" className="form-control box-register" />
            </div>
            <div className="form-group reg-password">
              <label for="exampleInputPassword1">KTP / Registration Number</label>
              <input type="text" className="form-control box-register" />
            </div>
            <div className="form-group reg-password">
              <label for="exampleInputPassword1">NPWP / Taxpayer Identification Number </label>
              <input type="password" className="form-control box-register" />
            </div>
            <p className="txt-user">USER ACCOUNT</p>
            <Row>
              <div className="form-group col">
                <label for="exampleInputEmail1 ">Email</label>
                <input type="email" className="form-control box-register" id="exampleInputEmail1" aria-describedby="emailHelp" />
              </div>
              <div className="form-group col">
                <label for="exampleInputEmail1">Username</label>
                <input type="text" className="form-control box-register" />
              </div>
            </Row>
            <Row>
              <div className="form-group col">
                <label for="exampleInputEmail1 ">Password</label>
                <input type="password" className="form-control box-register" id="exampleInputPassword1" />
              </div>
              <div className="form-group col">
                <label for="exampleInputEmail1">Confirm Password</label>
                <input type="password" className="form-control box-register" id="exampleInputPassword1" />
              </div>
            </Row>
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label" for="exampleCheck1">I have read and agree to the terms of service</label>
            </div>
            <button type="submit" className="btn btn-register">Register</button>
          </form>
          <div className="left-sign" >
            <div className="footer-sign">
              <p className="judul-sign">PT Indah Karya (Persero)</p>
              <p>Email : indahkarya@indahkarya.co.id</p>
              <p>Call : 022 7434093</p>
              <p>© 2021 PT Indah Karya (Persero). All Right Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPersonal;