import React, { Fragment, useContext, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import gSign from '../Utils/image/sign.png'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import './CSS/LoginRegister.css'
import './CSS/Proses.css'
import '../GlobalVariable'

import axios from 'axios'
import { AuthContext } from '../../App'
const qs = require('querystring')
const api = global.api

export default function Login(props) {

  const { state, dispatch } = useContext(AuthContext)

  const initialState = {
    username: "",
    password: "",
    // Agar buttonnya bisa di klik
    isSubmitting: false,
    // Jika salah memasukan usename atau password
    errorMessage: null
  }

  const stateForm = {
    username: "",
    password: ""
  }

  // Fungsi ini akan mengubah isi dari initial state
  const [data, setData] = useState(initialState)

  // Fungsi dari stateForm
  const [dataform, setDataForm] = useState(stateForm)

  const handleInputChange = event => {
    // Ketika form diinputkan data, maka akan merubah data state
    setDataForm({
      ...dataform,
      [event.target.name]: event.target.value
    })
  }

  // Fungsi ketika form di submit
  // Memanggil data username dan password lalu diproses menggunakan axios, menggunakan method post data
  const handleFormSubmit = event => {
    event.preventDefault()
    setData({
      ...data,
      // Jika berhasil submit maka tombolnya akan di non aktifkan
      isSubmitting: true,
      errorMessage: null
    })

    // Akan mengambil dari request body
    const requestBody = {
      username: dataform.username,
      password: dataform.password
    }

    // Konfigurasi untuk memanggil header di postman
    // konfigurasi x-www-form-urlencoded
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    // Panggil axios
    axios.post(api + '/auth/api/v1/login', qs.stringify(requestBody), config).then(res => {
      if (res.data.success === true && res.data.verifikasi === 1) {   // 1
        dispatch({
          type: "LOGIN",
          payload: res.data
        })
        props.history.push("/akses-role")
      } else if (res.data.success === true && res.data.verifikasi === 0) {  // 0
        setData({
          ...data,
          isSubmiting: false,
          // Kalau Error ganti Message jadi message
          errorMessage: "Email anda belum terverifikasi, silahkan cek email atau spam untuk verifikasi."
        })
      } else {
        setData({
          ...data,
          isSubmiting: false,
          // Kalau Error ganti Message jadi message
          errorMessage: res.data.Message
        })
      }
      throw res
    }).then(res => {
      // console.log(res);
    }).catch(e => {
      // console.log(e);
    });
  }

  // Cek autentikasi
  if (state.isAuthenticated) {
    return <Redirect to="/akses-role" />
  }

  return (
    <Fragment>
      <div className="ParentBox">
        <Container>
          <Row>
            <Col>
              <div className="left-sign">
                <img src={gSign} alt="..." />
                <FooterCopyright />
              </div>
            </Col>
            <Col>
              <div className="right-sign ml-4">
                <h1>Sign In</h1>
                <div className="underline-sign"></div>

                {/* Ketika form di submit */}
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Input type="text" className="box-login" placeholder="Email" name='username'
                      /* ini manggil fungsi */
                      value={dataform.username}
                      onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Input type="password" className="box-login" placeholder="Password" name='password'
                      /* ini manggil fungsi */
                      value={dataform.password}
                      onChange={handleInputChange} />
                  </FormGroup>
                  <Link to="/forgot-password" className="forgot-password">Forgot Password</Link> <br />

                  {/* Menampilkan Pesan error */}
                  {data.errorMessage && (
                    <div className="alert alert-danger pesan-login" role="alert">
                      {data.errorMessage}
                    </div>
                  )}
                  <button type="submit" className="btn"
                    // Cek dulu
                    // ketika lagi dklik maka tombol di nonaktifkan
                    disabled={data.isSubmitting}>
                    {data.isSubmitting ? (
                      // Ketika di submit
                      "..Loading"
                    ) :
                      (
                        // Ketika ngga di submit
                        "Login"
                      )}
                  </button>
                </Form>
                <p>Not a member? <Link to="/register-company" className="btn-ca">Create Account</Link> </p>
                <Link to="#" className="btn-tos"> <i>Download term of service</i> </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const FooterCopyright = () => {
  return (
    <div>
      <div className="footer-sign">
        <p className="judul-sign">PT Indah Karya (Persero)</p>
        <p>Email : indahkarya@indahkarya.co.id</p>
        <p>Call : 022 7434093</p>
        <p>© 2021 PT Indah Karya (Persero). All Right Reserved</p>
      </div>
    </div>
  )
}
