import React, { useContext, useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Input, Button, Alert } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import swal from 'sweetalert';

import axios from 'axios';
import NavSide from '../../NavSide';
import MenuComp from '../../MenuComp';
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';

const api = global.api

export default function TambahPengalaman(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // Untuk nampung data
    const stateForm = {
        id: '',
        id_user: state.id_user,
        role: state.role,
        response: '',
        display: 'none',
        nama_kontrak: '',   // Wajib diisi
        lokasi: '', // Wajib diisi
        instansi: '',   // Wajib diisi
        alamat: '',
        no_telepon: '',
        no_kontrak: '',
        tanggal_pelaksanaan: '',
        selesai_kontrak: '',
        nilai_kontrak: '',  // Wajib diisi
        presentase_pelaksanaan: '', // Wajib diisi
        tgl_serah_terima: '',
        keterangan: '', // Wajib diisi
    }
    const [dataform, setDataForm] = useState(stateForm)

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Jika ada perubahan di inputan, yang akan di simpan di dalam state
    const handleInputChange = event => {
        // Ketika form diinputkan data, maka akan merubah data state
        setDataForm({
            ...dataform,
            [event.target.name]: event.target.value
        })
    }

    // Fungsi Tambah data
    const AddPengalaman = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        if (dataform.nama_kontrak.length === 0) {
            setErrform({
                errform,
                errormessage: "Nama Kontrak belum diisi!!",
                display: 'block'
            })
        } else if (dataform.lokasi.length === 0) {
            setErrform({
                errform,
                errormessage: 'Lokasi belum diisi!!',
                display: 'block'
            })
        } else if (dataform.instansi.length === 0) {
            setErrform({
                errform,
                errormessage: 'Instansi belum diisi!!',
                display: 'block'
            })
        } else if (dataform.nilai_kontrak.length === 0) {
            setErrform({
                errform,
                errormessage: 'Nilai Kontrak belum diisi!!',
                display: 'block'
            })
        } else if (dataform.presentase_pelaksanaan.length === 0) {
            setErrform({
                errform,
                errormessage: 'Presentase Pelaksanaan belum diisi!!',
                display: 'block'
            })
        } else if (dataform.keterangan.length === 0) {
            setErrform({
                errform,
                errormessage: 'Keterangan belum diisi!!',
                display: 'block'
            })
        } else {
            swal({
                title: "Data berhasil ditambah!",
                text: "",
                icon: "success",
                button: "Back",
            }).then(
                axios.post(api + `/auth/api/v1/company/tambah-pengalaman`, {
                    id_user: dataform.id_user,
                    role: dataform.role,
                    nama_kontrak: dataform.nama_kontrak,
                    lokasi: dataform.lokasi,
                    instansi: dataform.instansi,
                    alamat: dataform.alamat,
                    no_telepon: dataform.no_telepon,
                    no_kontrak: dataform.no_kontrak,
                    tanggal_pelaksanaan: dataform.tanggal_pelaksanaan,
                    selesai_kontrak: dataform.selesai_kontrak,
                    keterangan: dataform.keterangan,
                    nilai_kontrak: dataform.nilai_kontrak,
                    presentase_pelaksanaan: dataform.presentase_pelaksanaan,
                    tgl_serah_terima: dataform.tgl_serah_terima
                }, config).then(json => {
                    if (json.data.status === 200) {
                        props.history.push("/pengalaman");
                    }
                })
            )
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-tambah-tenaga'>
                <MenuComp />
                <Form className='data-tenaga-ahli'>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label sm={2} className='box-width-pengalaman'>Nama Kontrak<font color="red"> *</font></Label>
                                <Col sm={10} >
                                    <Input type="textarea" name="nama_kontrak" value={dataform.nama_kontrak} onChange={handleInputChange} id="textArea" />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={2}>Lokasi<font color="red"> *</font></Label>
                                <Col sm={10}>
                                    <Input type="text" name="lokasi" value={dataform.lokasi} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={2}>Instansi<font color="red"> *</font></Label>
                                <Col sm={10}>
                                    <Input type="text" name="instansi" value={dataform.instansi} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={2}>Alamat</Label>
                                <Col sm={10}>
                                    <Input type="text" name="alamat" value={dataform.alamat} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={2}>No Telp</Label>
                                <Col sm={10}>
                                    <Input type="text" name="no_telepon" value={dataform.no_telepon} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <FormGroup row >
                                <Label sm={4} >No Kontrak</Label>
                                <Col sm={8}>
                                    <Input type="text" name="no_kontrak" value={dataform.no_kontrak} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={4}>Tanggal Pelaksanaan</Label>
                                <Col sm={8}>
                                    <Input type="date" name='tanggal_pelaksanaan' value={dataform.tanggal_pelaksanaan} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={4}>Selesai Kontrak</Label>
                                <Col sm={8}>
                                    <Input type="date" name='selesai_kontrak' value={dataform.selesai_kontrak} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <FormGroup row >
                                <Label sm={3}>Nilai Kontrak<font color="red">*</font></Label>
                                <Col sm={9}>
                                    <Input type="text" name="nilai_kontrak" value={dataform.nilai_kontrak} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={3}>Presentase Pelaksanaan<font color="red">**</font></Label>
                                <Col sm={9}>
                                    <Input type="text" name="presentase_pelaksanaan" value={dataform.presentase_pelaksanaan} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label for="exampleName" sm={3}>Tgl Serah terima</Label>
                                <Col sm={9}>
                                    <Input type="date" name='tgl_serah_terima' value={dataform.tgl_serah_terima} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup row>
                        <Label sm={2}>Keterangan<font color="red"> *</font></Label>
                        <Col sm={10}>
                            <Input type="textarea" name="keterangan" value={dataform.keterangan} onChange={handleInputChange} id="textArea" />
                        </Col>
                    </FormGroup>
                    <div className='bg-orange'>
                        <p className='c'><font color="red">*</font>  Data Ini wajib diisi</p>
                        <p><font color="red">** </font>Jika persentase pelaksanaan bernilai kurang dari 100 maka sistem akan menganggap sebagian pekerjaan sedang berjalan </p>
                    </div>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={AddPengalaman} >Save</Button>{' '}
                        <Link to={'/pengalaman'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
