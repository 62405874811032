import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../App'

export default function AksesRole() {
    const { state } = useContext(AuthContext)

    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }
    if (state.role === 1) {
        return <Redirect to="/data-perusahaan" />
    } 
    if (state.role === 2) {
        return <Redirect to="/" />
    } 
    if (state.role === 3) {
        return <Redirect to="/admin/daftar-pengajuan" />
    }

    return (
        <div>
            
        </div>
    )
}
