import React, { Fragment, useContext, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import gSign from '../Utils/image/sign.png'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import './CSS/LoginRegister.css'
import './CSS/Proses.css'
import '../GlobalVariable'

import axios from 'axios'
import { AuthContext } from '../../App'
const qs = require('querystring')
const api = global.api

export default function ForgotPassword(props) {

  const { state, dispatch } = useContext(AuthContext)

  const initialState = {
    username: "",
    // Agar buttonnya bisa di klik
    isSubmitting: false,
    // Jika salah memasukan usename atau password
    errorMessage: null,
    successMessage: null
  }

  const stateForm = {
    username: "",
  }

  // Fungsi ini akan mengubah isi dari initial state
  const [data, setData] = useState(initialState)

  // Fungsi dari stateForm
  const [dataform, setDataForm] = useState(stateForm)

  const handleInputChange = event => {
    // Ketika form diinputkan data, maka akan merubah data state
    setDataForm({
      ...dataform,
      [event.target.name]: event.target.value
    })
  }

  // Fungsi ketika form di submit
  // Memanggil data email lalu diproses menggunakan axios, menggunakan method post data
  const handleFormSubmit = event => {
    event.preventDefault()
    setData({
      ...data,
      // Jika berhasil submit maka tombolnya akan di non aktifkan
      isSubmitting: true,
      errorMessage: null,
      successMessage: null
    })

    // Akan mengambil dari request body
    const requestBody = {
      username: dataform.username,
    }

    // Konfigurasi untuk memanggil header di postman
    // konfigurasi x-www-form-urlencoded
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    // Panggil axios
    axios.post(api + '/auth/api/v1/kirim-forgot-password', qs.stringify(requestBody), config).then(res => {
      if (res.data.success === true) {   // 1
        setData({
          ...data,
          isSubmiting: false,
          // Kalau Error ganti Message jadi message
          errorMessage: null,
          successMessage: res.data.message
        })
      } else {
        setData({
          ...data,
          isSubmiting: false,
          // Kalau Error ganti Message jadi message
          errorMessage: "Email tidak terdaftar",
          successMessage: null
        })
      }
      throw res
    }).then(res => {
      // console.log(res);
    }).catch(e => {
      // console.log(e);
    });
  }

  // Cek autentikasi
  if (state.isAuthenticated) {
    return <Redirect to="/data-perusahaan" />
  }

  return (
    <Fragment>
      <div className="ParentBox">
        <Container>
          <Row>
            <Col>
              <div className="left-sign">
                <img src={gSign} alt="..." />
                <FooterCopyright />
              </div>
            </Col>
            <Col>
              <div className="right-sign ml-4">
                <h1>Forgot Password</h1>
                <div className="underline-sign"></div>

                {/* Ketika form di submit */}
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Input type="text" className="box-login" placeholder="Email" name='username'
                      /* ini manggil fungsi */
                      value={dataform.username}
                      onChange={handleInputChange} />
                  </FormGroup>

                  {/* Menampilkan Pesan error */}
                  {data.errorMessage && (
                    <div className="alert alert-danger pesan-login" role="alert">
                      {data.errorMessage}
                    </div>
                  )}
                  {data.successMessage && (
                    <div className="alert alert-success pesan-login" role="alert">
                      {data.successMessage}
                    </div>
                  )}
                  <button type="submit" className="btn"
                    // Cek dulu
                    // ketika lagi dklik maka tombol di nonaktifkan
                    disabled={data.isSubmitting}>
                    {data.isSubmitting ? (
                      // Ketika di submit
                      "..Loading"
                    ) :
                      (
                        // Ketika ngga di submit
                        "Send"
                      )}
                  </button>
                </Form>
                <p>Not a member? <Link to="/register-company" className="btn-ca">Create Account</Link> </p>

                <Link to="/" >
                  <button type="submit" className="btn">
                    Back to Login
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

const FooterCopyright = () => {
  return (
    <div>
      <div className="footer-sign">
        <p className="judul-sign">PT Indah Karya (Persero)</p>
        <p>Email : indahkarya@indahkarya.co.id</p>
        <p>Call : 022 7434093</p>
        <p>© 2021 PT Indah Karya (Persero). All Right Reserved</p>
      </div>
    </div>
  )
}
