import React, { useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Label, Row, Col, Input, Button, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert';

import axios from 'axios'
import NavSide from '../../NavSide';
import MenuComp from '../../MenuComp';
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';
import qs from 'querystring'
import { DataContext } from '../../../../DataContext';

const api = global.api

export default function TambahTaCV(props) {
    const { state, dispatch } = useContext(AuthContext)

    // Panggil nama dari Data Context TambahTenagaAhli
    let { taNama } = useContext(DataContext)
    const [ dataPengalaman, setDataPengalaman ] = useState([])
    const [ dataPendidikan, setDataPendidikan ] = useState([])
    const [ dataSertifikat, setDataSertifikat ] = useState([])

    // Untuk tampung data ta_pengalaman
    const formPengalaman = {
        response: '',
        display: 'none',
        id_user: state.id_user,
        tahun: '',
        uraian: '',
    }
    const [ statePengalaman, setStatePengalaman ] = useState(formPengalaman)

    // Untuk tampung data ta_pendidikan
    const formPendidikan = {
        response: '',
        display: 'none',
        id_user: state.id_user,
        tahun: '',
        uraian: '',
    }
    const [ statePendidikan, setStatePendidikan ] = useState(formPendidikan)

    // Untuk tampung data ta_sertifikat
    const formSertifikat = {
        response: '',
        display: 'none',
        id_user: state.id_user,
        tahun: '',
        uraian: '',
    }
    const [ stateSertifikat, setStateSertifikat ] = useState(formSertifikat)

    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }

    // Handle Input Change
    const handleInputChangePengalaman = event => {
        setStatePengalaman({
            ...statePengalaman,
            [event.target.name]: event.target.value
        })
    }
    const handleInputChangePendidikan = event => {
        setStatePendidikan({
            ...statePendidikan,
            [event.target.name]: event.target.value
        })
    }
    const handleInputChangeSertifikat = event => {
        setStateSertifikat({
            ...stateSertifikat,
            [event.target.name]: event.target.value
        })
    }

    // Fungsi Hapus ta_pengalaman berdasarkan id
    const HapusTaPengalaman = (idTaPengalaman) => {
        const data = qs.stringify({
            id: idTaPengalaman
        })

        axios.delete(api + '/auth/api/v1/company/hapus-ta-pengalaman', {
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + state.token
            }
        }).then(json => {
            if (json.data.status === 200) {
                axios.get(api + `/auth/api/v1/company/tampil-ta-pengalaman/${state.id_user}/${taNama}`, config)
                    .then(res => {
                        setDataPengalaman(res.data.values)
                    })
            }
        })
    }

    // Fungsi Hapus ta_pendidikan berdasarkan id
    const HapusTaPendidikan = (idTaPendidikan) => {
        const data = qs.stringify({
            id: idTaPendidikan
        })

        axios.delete(api + '/auth/api/v1/company/hapus-ta-pendidikan', {
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + state.token
            }
        }).then(json => {
            if (json.data.status === 200) {
                axios.get(api + `/auth/api/v1/company/tampil-ta-pendidikan/${state.id_user}/${taNama}`, config)
                    .then(res => {
                        setDataPendidikan(res.data.values)
                    })
            }
        })
    }
    // Fungsi Hapus ta_pendidikan berdasarkan id
    const HapusTaSertifikat = (idTaSertifikat) => {
        const data = qs.stringify({
            id: idTaSertifikat
        })

        axios.delete(api + '/auth/api/v1/company/hapus-ta-sertifikat', {
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + state.token
            }
        }).then(json => {
            if (json.data.status === 200) {
                axios.get(api + `/auth/api/v1/company/tampil-ta-sertifikat/${state.id_user}/${taNama}`, config)
                    .then(res => {
                        setDataSertifikat(res.data.values)
                    })
            }
        })
    }

    // Untuk menampilkan error jika data belum diisi
    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Tambah data ta_pengalaman
    const AddTaPengalaman = () => {
        if (statePengalaman.tahun.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Tahun belum diisi",
                display: 'block',
            })
        } else if (statePengalaman.uraian.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Uraian belum diisi",
                display: 'block',
            })
        } else {
            axios.post(api + '/auth/api/v1/company/tambah-ta-pengalaman',
                {
                    id_user: statePengalaman.id_user,
                    nama: taNama,
                    tahun: statePengalaman.tahun,
                    uraian: statePengalaman.uraian,
                }, config)
                .then(
                    axios.get(api + `/auth/api/v1/company/tampil-ta-pengalaman/${state.id_user}/${taNama}`, config)
                        .then(res => {
                            setDataPengalaman(res.data.values)
                        })
                )
        }
    }

    // Fungsi Tambah data ta_pendidikan
    const AddTaPendidikan = () => {
        if (statePendidikan.tahun.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Tahun belum diisi",
                display: 'block',
            })
        } else if (statePendidikan.uraian.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Uraian belum diisi",
                display: 'block',
            })
        } else {
            axios.post(api + '/auth/api/v1/company/tambah-ta-pendidikan',
                {
                    id_user: statePendidikan.id_user,
                    nama: taNama,
                    tahun: statePendidikan.tahun,
                    uraian: statePendidikan.uraian,
                }, config)
                .then(
                    axios.get(api + `/auth/api/v1/company/tampil-ta-pendidikan/${state.id_user}/${taNama}`, config)
                        .then(res => {
                            setDataPendidikan(res.data.values)
                        })
                )
        }
    }

    // Fungsi Tambah data ta_pendidikan
    const AddTaSertifikat = () => {
        if (stateSertifikat.tahun.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Tahun belum diisi",
                display: 'block',
            })
        } else if (stateSertifikat.uraian.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Uraian belum diisi",
                display: 'block',
            })
        } else {
            axios.post(api + '/auth/api/v1/company/tambah-ta-sertifikat',
                {
                    id_user: stateSertifikat.id_user,
                    nama: taNama,
                    tahun: stateSertifikat.tahun,
                    uraian: stateSertifikat.uraian,
                }, config)
                .then(
                    axios.get(api + `/auth/api/v1/company/tampil-ta-sertifikat/${state.id_user}/${taNama}`, config)
                        .then(res => {
                            setDataSertifikat(res.data.values)
                        })
                )
        }
    }

    const saveButton = () => {
        swal({
            title: "Data berhasil di tambah!",
            text: "",
            icon: "success",
            button: "Back",
        }).then(
            props.history.push("/tenaga-ahli")
        )
    }

    const timeOut = () => {
        // Menghitung Mundur
        setTimeout(() => {
            // console.log("Token Berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar terlogout otomatis
    useEffect(() => {
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek auth
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-tambah-tenaga'>
                <MenuComp />
                <Form className='data-tenaga-ahli'>
                    <Row>
                        <Col>
                            <h5>Curriculum Vitae (CV)</h5>
                        </Col>
                    </Row>
                    {/* Pengalaman */}
                    <Row>
                        <Col>
                            <p>Pengalaman</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataPengalaman.map(dataPengalaman =>
                                <FormGroup row key={dataPengalaman.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataPengalaman.tahun} disabled />
                                    </Col>
                                    <Col sm={9}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataPengalaman.uraian} disabled />
                                    </Col>
                                    <Col sm={1}>
                                        <Label>&nbsp;</Label>
                                        <Button className='btn-bg-transparent ta-trash' onClick={() => HapusTaPengalaman(dataPengalaman.id)}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row >
                                <Col sm={2}>
                                    <Label>Tahun</Label>
                                    <Input type="text" name="tahun" value={statePengalaman.tahun} onChange={handleInputChangePengalaman} />
                                </Col>
                                <Col sm={9}>
                                    <Label>Uraian</Label>
                                    <Input type="textarea" name="uraian" value={statePengalaman.uraian} onChange={handleInputChangePengalaman} />
                                </Col>
                                <Col sm={3}>
                                    <Button color="secondary" style={{ color: "#fff" }} onClick={AddTaPengalaman}>
                                        <i className="fas fa-plus-circle"></i> Tambah
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* Pendidikan */}
                    <Row>
                        <Col>
                            <p>Pendidikan</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataPendidikan.map(dataPendidikan =>
                                <FormGroup row key={dataPendidikan.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataPendidikan.tahun} disabled />
                                    </Col>
                                    <Col sm={9}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataPendidikan.uraian} disabled />
                                    </Col>
                                    <Col sm={1}>
                                        <Label>&nbsp;</Label>
                                        <Button className='btn-bg-transparent' onClick={() => HapusTaPendidikan(dataPendidikan.id)}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row >
                                <Col sm={2}>
                                    <Label>Tahun</Label>
                                    <Input type="text" name="tahun" value={statePendidikan.tahun} onChange={handleInputChangePendidikan} />
                                </Col>
                                <Col sm={9}>
                                    <Label>Uraian</Label>
                                    <Input type="textarea" name="uraian" value={statePendidikan.uraian} onChange={handleInputChangePendidikan} />
                                </Col>
                                <Col sm={3}>
                                    <Button color="secondary" style={{ color: "#fff" }} onClick={AddTaPendidikan}>
                                        <i className="fas fa-plus-circle"></i> Tambah
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* Sertifikat */}
                    <Row>
                        <Col>
                            <p>Sertifikat</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataSertifikat.map(dataSertifikat =>
                                <FormGroup row key={dataSertifikat.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataSertifikat.tahun} disabled />
                                    </Col>
                                    <Col sm={9}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataSertifikat.uraian} disabled />
                                    </Col>
                                    <Col sm={1}>
                                        <Label>&nbsp;</Label>
                                        <Button className='btn-bg-transparent' onClick={() => HapusTaSertifikat(dataSertifikat.id)}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row >
                                <Col sm={2}>
                                    <Label>Tahun</Label>
                                    <Input type="text" name="tahun" value={stateSertifikat.tahun } onChange={handleInputChangeSertifikat} />
                                </Col>
                                <Col sm={9}>
                                    <Label>Uraian</Label>
                                    <Input type="textarea" name="uraian" value={stateSertifikat.uraian } onChange={handleInputChangeSertifikat} />
                                </Col>
                                <Col sm={3}>
                                    <Button color="secondary" style={{ color: "#fff" }} onClick={AddTaSertifikat}>
                                        <i className="fas fa-plus-circle"></i> Tambah
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={saveButton}>Save</Button>{' '}
                    </div>
                </Form>
            </div>
        </div>
    )
}
