import React from "react";
import logo_verifikasi from "../Utils/image/verify.png"

const VerifikasiEmail = () => {
  return (
    <div className="ParentBox">
      <div className="container">
        <div className="verifikasi-email">
          <img src={logo_verifikasi} alt="..."></img>
          <p>Pendaftaran sebagai calon Rekanan PT Indah Karya (Persero) Silahkan cek Email yang sudah anda daftarkan (Inbox/Spam)</p>
          <p className="txt-verif"> <i>Registration as a prospective Partner of PT Indah Karya (Persero) Please check your registered email (Inbox/Spam)</i></p>
        </div>
      </div>
    </div>
  )
}

export default VerifikasiEmail;