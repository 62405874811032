import React from 'react'
import '../CSS/NavSide.css'
import { Nav } from 'reactstrap';
import logo from '../../Utils/image/logo.png'
import { Link, withRouter } from 'react-router-dom'

const activeTab = (history, path) => {
    if (history.location.pathname === path) {
        return { 
            "color": "#2E2E38",
            "backgroundColor": "#FFFFFF"
        };
    }
};

function NavSide({history}) {
    return (
        <div>
            <div className='nav-side'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link to="/admin/daftar-pengajuan" className='body-btn' style={activeTab(history, "/admin/daftar-pengajuan")}>
                        <p className='textt'>Daftar Pengajuan</p>
                    </Link>
                    <Link to="/admin/daftar-rekanan" className='body-btn' style={activeTab(history, "/admin/daftar-rekanan")}>
                        <p className='textt'>Daftar Rekanan</p>
                    </Link>
                    <Link to="/admin/daftar-bukan-rekanan" className='body-btn' style={activeTab(history, "/admin/daftar-bukan-rekanan")}> 
                        <p className='textt'>Daftar Bukan Rekanan</p>
                    </Link>
                </Nav>
            </div>
        </div>
    )
}

export default withRouter(NavSide);