import React, { useEffect, useState, useContext } from 'react';
import { Form, FormGroup, Label, Col, Input, Button, Alert } from 'reactstrap'
import axios from 'axios'
import { AuthContext } from '../../../../App'
import { Redirect, Link } from 'react-router-dom'
import MenuComp from '../../MenuComp'
import NavSide from '../../NavSide'
import swal from 'sweetalert';
import '../../../GlobalVariable'

const api = global.api

export default function TambahPemilik(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // Untuk nampung data
    const stateForm = {
        id_akta: "",
        id_user: state.id_user,
        role: state.role,
        nama: "",
        ktp_pemilik: "",
        alamat: "",
        saham: "",
    }
    const [dataform, setDataForm] = useState(stateForm)

    // Fungsi Jika ada perubahan di inputan, yang akan di simpan di dalam state
    const handleInputChange = event => {
        // Ketika form diinputkan data, maka akan merubah data state
        setDataForm({
            ...dataform,
            [event.target.name]: event.target.value
        })
    }

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Tambah data
    const AddPemilik = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }
        
        if (dataform.nama.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nama belum diisi!!",
                display: 'block'
            })
        } else if (dataform.ktp_pemilik.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nomor KTP belum diisi!!",
                display: 'block'
            })
        } else if (dataform.alamat.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Alamat belum diisi!!",
                display: 'blcok'
            })
        } else if (dataform.saham.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Saham belum diisi!!",
                display: 'block'
            })
        } else {
            swal({
                title: "Data berhasil ditambah!",
                text: "",
                icon: "success",
                button: "Back",
            }).then(
                axios.post(api + '/auth/api/v1/company/tambah-pemilik', {
                    id_user: dataform.id_user,
                    nama: dataform.nama,
                    ktp_pemilik: dataform.ktp_pemilik,
                    alamat: dataform.alamat,
                    saham: dataform.saham,
                    role: dataform.role
                }, config).then(json => {
                    if (json.data.status === 200) {
                        props.history.push("/pemilik")
                    }
                })
            )
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                <Form className='data-perusahaan'>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Nama<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="nama" value={dataform.nama} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Nomor KTP<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="ktp_pemilik" value={dataform.ktp_pemilik} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Alamat<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="alamat" value={dataform.alamat} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Saham<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="saham" value={dataform.saham} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <div className='bg-orange'>
                        <p className='c'><font color="red">*</font>  Data Ini wajib diisi</p>
                        <p><font color="red">**</font> Masukkan 0 (nol) jika tidak memiliki saham</p>
                    </div>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={AddPemilik} >Save</Button>{' '}
                        <Link to={'/pemilik'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
