import React, { useEffect, useState, useContext } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Table, Button, Row, Col, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom';
import NavSide from '../NavSide';
import MenuComp from '../MenuComp';
import '../../GlobalVariable'
import axios from 'axios'
import { AuthContext } from '../../../App';
import swal from 'sweetalert'

const api = global.api

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AjukanRekanan(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // Tampung data use_company
    const [user_company, setuser_company] = useState([])

    const [izin_usaha, setizin_usaha] = useState({ izin_usahas: [] })

    const [akta, setAkta] = useState({ aktas: [] })

    const [pemilik, setPemilik] = useState({ pemiliks: [] })

    const [pengurus, setPengurus] = useState({ penguruss: [] })

    const [tenaga_ahli, setTenaga_ahli] = useState({ tenaga_ahlis: [] })

    const [pengalaman, setPengalaman] = useState({ pengalamans: [] })

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        // User Company
        axios.get(api + `/auth/api/v1/company/tampil-user-company/${state.id_user}`, config)
            .then(res => {
                setuser_company(res.data.values)
            })

        // Izin Usaha
        axios.get(api + `/auth/api/v1/company/tampil-izin-usaha/${state.id_user}`, config).then(res => {
            setizin_usaha({
                izin_usahas: res.data.values
            })
        })

        // Akta
        axios.get(api + `/auth/api/v1/company/tampil-akta/${state.id_user}`, config).then(res => {
            setAkta({
                aktas: res.data.values
            })
        })

        // Pemilik
        axios.get(api + `/auth/api/v1/company/tampil-pemilik/${state.id_user}`, config).then(res => {
            setPemilik({
                pemiliks: res.data.values
            })
        })

        // Pengurus
        axios.get(api + `/auth/api/v1/company/tampil-pengurus/${state.id_user}`, config).then(res => {
            setPengurus({
                penguruss: res.data.values
            })
        })

        // Tenaga Ahli
        axios.get(api + `/auth/api/v1/company/tampil-tenaga-ahli/${state.id_user}`, config).then(res => {
            setTenaga_ahli({
                tenaga_ahlis: res.data.values
            })
        })

        // Pengalaman
        axios.get(api + `/auth/api/v1/company/tampil-pengalaman/${state.id_user}`, config).then(res => {
            setPengalaman({
                pengalamans: res.data.values
            })
        })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        // jumlah()
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    const handleClickEditRekanan = () => {
        swal({
            title: "Apakah anda yakin akan ajukan rekanan?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.post(api + `/ubah-rekanan`, {
                    id_company: state.id_user,
                    rekanan: 1,
                    tgl_rekanan: new Date(),
                }).then(json => {
                    if (json.data.status === 200) {
                        props.history.push("/rekanan");
                    }
                })
                swal("Berhasil ajukan rekanan, sekarang data anda sedang dalam proses verifikasi!", {
                    icon: "success",
                });
            } else {
                swal("Gagal ajukan rekanan!");
            }
        });
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kesatu'>
                <MenuComp />
                <div className='bg-kelima-ajukan'>
                    <div className='container mx-auto w-75'>
                        <h4 className='d-flex justify-content-around'>Status Kelengkapan Administrasi</h4>
                        <br />
                        <Table>
                            <thead className='table-header'>
                                <tr>
                                    <th>#</th>
                                    <th>Parameter</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Data Perusahaan</td>
                                    {user_company.map(user_company =>
                                        <td key={user_company}>
                                            {user_company.kota.length === 0 ? (
                                                // Ketika di submit
                                                <font color='red'>Belum Isi</font>
                                            ) :
                                                (
                                                    // Ketika ngga di submit
                                                    <font color='green'>Sudah Isi</font>
                                                )}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Izin Usaha</td>
                                    <td>
                                        {izin_usaha.izin_usahas.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Akta</td>
                                    <td>
                                        {akta.aktas.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Pemilik</td>
                                    <td>
                                        {pemilik.pemiliks.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>Pengurus</td>
                                    <td>
                                        {pengurus.penguruss.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>Tenaga Ahli</td>
                                    <td>
                                        {tenaga_ahli.tenaga_ahlis.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>Pengalaman</td>
                                    <td>
                                        {pengalaman.pengalamans.length === 0 ? (
                                            // Ketika di submit
                                            <font color='red'>Belum Isi</font>
                                        ) :
                                            (
                                                // Ketika ngga di submit
                                                <font color='green'>Sudah Isi</font>
                                            )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <br />
                        <Row>
                            <Col sm={8}>

                            </Col>
                            <Col sm={4} >
                                <div className='group-ajukan mx-auto'>
                                    {state.rekanan === 0 ? <><Button type='button' className='btn btn-ajukan' onClick={handleClickEditRekanan}>Daftar Jadi Rekanan</Button><p className='p-ajukan'><i>Apabila data sudah dilengkapi, klik tombol daftar agar data diverifikasi oleh Indah Karya</i></p></>
                                        : state.rekanan === 1 ? <Alert color="info">Dalam Proses Verifikasi</Alert>
                                            : state.rekanan === 3 ? <Alert color="success">Anda Sudah Menjadi Rekanan</Alert>
                                                : <Alert color="danger">Anda tidak diterima sebagai Rekanan</Alert>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
