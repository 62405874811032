import React, { useEffect, useState, useContext } from 'react'
import { CustomInput, Form, FormGroup, Label, Col, Input, Button, Alert } from 'reactstrap'
import axios from 'axios'
import { AuthContext } from '../../../../App'
import { Redirect, Link } from 'react-router-dom'
import MenuComp from '../../MenuComp'
import NavSide from '../../NavSide'
import swal from 'sweetalert';
import '../../../GlobalVariable'

const api = global.api

export default function TambahIzinUsaha(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // Show and Hide radio button
    const [show, setShow] = useState(false)

    // Untuk nampung data
    const stateForm = {
        id_akta: "",
        id_user: state.id_user,
        role: state.role,
        jenis_izin: "",
        nomor_surat: "",
        berlaku_sampai: "",
        instansi_pemberi: "",
    }
    const [dataform, setDataForm] = useState(stateForm)

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Jika ada perubahan di inputan, yang akan di simpan di dalam state
    const handleInputChange = event => {
        // Ketika form diinputkan data, maka akan merubah data state
        setDataForm({
            ...dataform,
            [event.target.name]: event.target.value
        })
    }

    // Fungsi Tambah data
    const AddIzinUsaha = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        if (dataform.jenis_izin.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Jenis Izin belum diisi!!",
                display: 'block'
            })
        } else if (dataform.nomor_surat.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nomor Surat belum diisi!!",
                display: 'block'
            })
        } else if (dataform.berlaku_sampai.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Berlaku Sampai belum diisi!!",
                display: 'block'
            })
        } else if (dataform.instansi_pemberi.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Instansi Pemberi belum diisi!!",
                display: 'block'
            })
        } else {
            swal({
                title: "Data berhasil ditambah!",
                text: "",
                icon: "success",
                button: "Back",
            }).then(
                axios.post(api + `/auth/api/v1/company/tambah-izin-usaha`, {
                    id_user: dataform.id_user,
                    jenis_izin: dataform.jenis_izin,
                    nomor_surat: dataform.nomor_surat,
                    berlaku_sampai: dataform.berlaku_sampai,
                    instansi_pemberi: dataform.instansi_pemberi,
                    role: dataform.role
                }, config).then(json => {
                    if (json.data.status === 200) {
                        // this.setState({
                        //     response: json.data.values,
                        //     display: 'block'
                        // })
                        props.history.push("/izin-usaha");
                    } else {
                        // this.setState({
                        //     response: json.data.values,
                        //     display: 'block'
                        // })
                    }
                })
            )
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                {/* <Alert color='success' style={{ display: this.state.display }}>
                    {this.state.response}
                </Alert> */}
                <Form className='data-perusahaan'>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Jenis Izin<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="jenis_izin" value={dataform.jenis_izin} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Nomor Surat<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="nomor_surat" value={dataform.nomor_surat} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Kota" sm={3}>Berlaku Sampai<font color="red"> *</font></Label>
                        <Col sm={8} onChange={handleInputChange}>
                            <CustomInput type="radio" id="exampleCustomRadio6" name="berlaku_sampai" value="" label="Tanggal" onClick={() => setShow(true)} inline />
                            <CustomInput type="radio" id="exampleCustomRadio7" name="berlaku_sampai" value="Seumur Hidup" label="Seumur Hidup" onClick={() => setShow(false)} inline />
                        </Col>
                    </FormGroup>
                    {
                        show ? <FormGroup row >
                            <Label for="Kota" sm={3}></Label>
                            <Col sm={4}>
                                <Input type="date" name='berlaku_sampai' value={dataform.berlaku_sampai} onChange={handleInputChange} />
                            </Col>
                        </FormGroup> : null
                    }
                    <FormGroup row >
                        <Label for="exampleName" sm={3}>Instansi Pemberi<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="instansi_pemberi" value={dataform.instansi_pemberi} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <div className='bg-orange'>
                        <p><font color="red">*</font>  Data Ini wajib diisi</p>
                    </div>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={AddIzinUsaha} >Save</Button>{' '}
                        <Link to={'/izin-usaha'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
