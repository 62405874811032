import './App.css';
import React, { useReducer, createContext, useState } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

// Auth
import Login from './components/Fungsional/Login'
import RegisterCompany from './components/Fungsional/RegisterCompany'
import RegisterPersonal from './components/Fungsional/RegisterPersonal'
import VerifikasiEmail from './components/Fungsional/VerifikasiEmail'
import VerifikasiBerhasil from './components/Fungsional/VerifikasiBerhasil';
import ForgotPassword from './components/Fungsional/ForgotPasstword';
import ChangeForgotPassword from './components/Fungsional/ChangeForgotPassword';

import AksesRole from './components/Fungsional/AksesRole'
import Coba from './components/Fungsional/Coba' // Untuk akses sudah login atau belum

// Crud Company
import DataPerusahaan from './components/Fungsional/Company/DataPerusahaan';
import EditDataPerusahaan from './components/Fungsional/Company/Proses/EditDataPerusahaan';
import IzinUsaha from './components/Fungsional/Company/IzinUsaha';
import TambahIzinUsaha from './components/Fungsional/Company/Proses/TambahIzinUsaha';
import Akta from './components/Fungsional/Company/Akta';
import TambahAkta from './components/Fungsional/Company/Proses/TambahAkta';
import EditAkta from './components/Fungsional/Company/Proses/EditAkta';
import Pemilik from './components/Fungsional/Company/Pemilik';
import TambahPemilik from './components/Fungsional/Company/Proses/TambahPemilik';
import Pengurus from './components/Fungsional/Company/Pengurus';
import TambahPengurus from './components/Fungsional/Company/Proses/TambahPengurus';
import TenagaAhli from './components/Fungsional/Company/TenagaAhli';
import TambahTenagaAhli from './components/Fungsional/Company/Proses/TambahTenagaAhli';
import Pengalaman from './components/Fungsional/Company/Pengalaman';
import TambahPengalaman from './components/Fungsional/Company/Proses/TambahPengalaman';
import AjukanRekanan from './components/Fungsional/Company/AjukanRekanan';
import TambahTaCV from './components/Fungsional/Company/Proses/TambahTaCV';

// Halaman Admin
import DaftarRekananAdmin from './components/Fungsional/Admin/DaftarRekananAdmin';
import BukanRekananAdmin from './components/Fungsional/Admin/BukanRekananAdmin';
import DaftarPengajuan from './components/Fungsional/Admin/DaftarPengajuan';

// Untuk Lihat data user di halaman admin
import { DataContext } from './DataContext';
// Proses Admin
import TampilDataPerusahaanAdmin from './components/Fungsional/Admin/Proses/TampilDataPerusahaanAdmin';
import TampilIzinUsahaAdmin from './components/Fungsional/Admin/Proses/TampilIzinUsahaAdmin';
import TampilAktaAdmin from './components/Fungsional/Admin/Proses/TampilAktaAdmin';
import TampilPemilikAdmin from './components/Fungsional/Admin/Proses/TampilPemilikAdmin';
import TampilPengurusAdmin from './components/Fungsional/Admin/Proses/TampilPengurusAdmin';
import TampilTenagaAhliAdmin from './components/Fungsional/Admin/Proses/TampilTenagaAhliAdmin';
import LihatTenagaAhli from './components/Fungsional/Admin/Proses/LihatTenagaAhli';
import TampilPengalamanAdmin from './components/Fungsional/Admin/Proses/TampilPengalamanAdmin';
import LihatPengalaman from './components/Fungsional/Admin/Proses/LihatPengalaman';

// Context untuk export data dan fungsi secara global
export const AuthContext = createContext()

// Inisiasi state
const initialState = {
  // Jika belum login maka false
  isAuthenticated: false,
  user: null,
  token: null,

  // JWT
  tokenExpires: 0,

  // Tampung data role dan id_company
  role: 0,
  id_company: 0,

  rekanan: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user_id", JSON.stringify(action.payload.currUser))
      localStorage.setItem("token", JSON.stringify(action.payload.token))
      // window.sessionStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        // Simpan di state
        ...state,
        // Jika berhasil login
        isAuthenticated: true,
        user: action.payload.username,
        token: action.payload.token,

        // Ketika login tambahkan data ini
        tokenExpires: action.payload.expires,
        role: action.payload.role,
        id_user: action.payload.currUser,
        rekanan: action.payload.rekanan,
      }
    case "LOGOUT":
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    default:
      return state
  }
}

const App = () => {
  // Merubah data menjadi global
  const [state, dispatch] = useReducer(reducer, initialState)

  // Tampung data id_company untuk lihat data di halaman admin
  let [ lihatData, setLihatdata ] = useState()

  // menampung data akta

  // Tenaga Ahli user_company
  let [ taNama, setTanama ] = useState()

  return (
    <HashRouter>
      <Switch>
        <AuthContext.Provider value={{
          state,
          dispatch
        }}>

          <Route exact path="/" component={Login} />
          <Route exact path="/register-company" component={RegisterCompany} />
          <Route exact path="/register-personal" component={RegisterPersonal} />
          <Route exact path="/akses-role" component={AksesRole} />
          <Route exact path="/success-registration" component={VerifikasiEmail} />
          <Route exact path="/verify" component={VerifikasiBerhasil} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/forgot-password/change-password/:token" component={ChangeForgotPassword} />

          {/* Fungsi CRUD */}
          <Route exact path="/data-perusahaan" component={DataPerusahaan} />
          <Route exact path="/data-perusahaan/edit" component={EditDataPerusahaan} />
          <Route exact path="/izin-usaha" component={IzinUsaha} />
          <Route exact path="/izin-usaha/tambah" component={TambahIzinUsaha} />
          <Route exact path="/akta" component={Akta} />
          <Route exact path="/akta/tambah" component={TambahAkta } />
          <Route exact path="/akta/edit" component={EditAkta} />
          <Route exact path="/pemilik" component={Pemilik} />
          <Route exact path="/pemilik/tambah" component={TambahPemilik} />
          <Route exact path="/pengurus" component={Pengurus} />
          <Route exact path="/pengurus/tambah" component={TambahPengurus} />
          <DataContext.Provider value={{taNama, setTanama}}>
            <Route exact path="/tenaga-ahli" component={TenagaAhli} />
            <Route exact path="/tenaga-ahli/tambah" component={TambahTenagaAhli} />
            <Route exact path="/pengalaman/tambah/cv" component={TambahTaCV} />
          </DataContext.Provider>
          <Route exact path="/pengalaman" component={Pengalaman} />
          <Route exact path="/pengalaman/tambah" component={TambahPengalaman} />
          <Route exact path="/rekanan" component={AjukanRekanan} />

          {/* <Route exact path="/show-form" component={CobaShowFrom } /> */}

          {/* Agar lihatData dapat dipakai secara global */}
          <DataContext.Provider value={{lihatData, setLihatdata}}>
            {/* Admin */}
            <Route exact path="/admin/daftar-rekanan" component={DaftarRekananAdmin} />
            <Route exact path="/admin/daftar-bukan-rekanan" component={BukanRekananAdmin} />
            <Route exact path="/admin/daftar-pengajuan" component={DaftarPengajuan} />

            {/* Proses Admin */}
            <Route exact path="/admin/data-perusahaan" component={TampilDataPerusahaanAdmin} />
            <Route exact path="/admin/izin-usaha" component={TampilIzinUsahaAdmin} />
            <Route exact path="/admin/akta" component={TampilAktaAdmin } />
            <Route exact path="/admin/pemilik" component={TampilPemilikAdmin} />
            <Route exact path="/admin/pengurus" component={TampilPengurusAdmin} />
            <Route exact path="/admin/tenaga-ahli" component={TampilTenagaAhliAdmin} />
            <Route exact path="/admin/tenaga-ahli/lihat" component={LihatTenagaAhli } />
            <Route exact path="/admin/pengalaman" component={TampilPengalamanAdmin} />
            <Route exact path="/admin/pengalaman/lihat" component={LihatPengalaman} />
          </DataContext.Provider>

          <Route exact path="/coba" component={Coba} />

        </AuthContext.Provider>
      </Switch>
    </HashRouter>
  );
}

export default App;
