import React, { useEffect, useState, useContext } from 'react';
import { Table, Nav, Button } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios'
import { AuthContext } from '../../../../App';
import '../../../GlobalVariable';
import { DataContext } from '../../../../DataContext';
import logo from '../../../Utils/image/logo.png';
import MenuCompLihat from './MenuCompLihat';

const api = global.api

export default function TampilPengalamanAdmin(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)
    let { lihatData } = useContext(DataContext)

    const [pengalaman, setPengalaman] = useState([])

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)   // yang ditampilkan per satu halaman
    const [itemsPerPage] = useState(5)  // menampilkan 5 item
    // State untuk tunjukan batas halaman
    const [pageNumberLimit] = useState(4) // menampilkan berapa banyak halaman nomor yang akan ditampilkan
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(4) // Batas nomor halaman maksimum
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    const handleClick = (event) => { // targetnya adalah id yang merupakan nomor
        setCurrentPage(Number(event.target.id))
    }

    const pages = [];   // berisi jumlah total halaman
    // data array akan menggunakan loop, data akan dibagi dengan itemsPerPage
    for (let i = 1; i <= Math.ceil(pengalaman.length / itemsPerPage); i++) {
        pages.push(i);
    }

    // Berapa banyak item yang diinginkan
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFistItem = indexOfLastItem - itemsPerPage;

    // untuk merender nomor halaman
    const renderPageNumbers = pages.map(number => {

        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                // Pada className, jika halaman saat ini sama dengan nomor halaman, maka tambahkan class active, yang lain jangan lakukan apapun
                <li key={number} id={number} onClick={handleClick}
                    className={currentPage === number ? "active" : null}
                >
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })

    // Search Bar
    const [searchTerm, setSearchTerm] = useState('')

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-pengalaman/${lihatData}`, config)
            .then(res => {
                setPengalaman(res.data.values)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, []);

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    if (state.role !== 3) {
        return <Redirect to="/" />
    }

    // Pagination
    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1)

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }

    const handlePrevBtn = () => {
        setCurrentPage(currentPage - 1)

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    // Untuk memberikan tampilan ketika mempunyai banyak data
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextBtn}> &hellip;</li>
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevBtn}> &hellip;</li>
    }

    const currentItems = pengalaman.filter((pengalaman) => {
        if (searchTerm === "") {
            return pengalaman
        } else if (pengalaman.nama_kontrak.toLowerCase().includes(searchTerm.toLowerCase())) {
            return pengalaman
        }
    }).slice(indexOfFistItem, indexOfLastItem)  // Iris data untuk setiap halaman

    return (
        <div>
            <div className='nav-sides'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link className='body-btn' to='/admin/data-perusahaan' >
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link className='body-btn' to='/admin/izin-usaha' >
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/admin/akta" className='body-btn' >
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/admin/pemilik" className='body-btn' >
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/admin/pengurus" className='body-btn' >
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/admin/tenaga-ahli" className='body-btn' >
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/admin/pengalaman" className='body-btn active-background' >
                        <p className='textt'>Pengalaman</p>
                    </Link>
                </Nav>
            </div>
            <div className='bg-kedua'>
                <MenuCompLihat />
                <div className='bg-ketiga-tambah-height'>
                    <div className='d-flex flex-row-reverse'>
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" onChange={event => { setSearchTerm(event.target.value) }} />
                        </div>
                    </div>

                    <Table>
                        <thead className='table-header'>
                            <tr>
                                <th>Pekerjaan</th>
                                <th>Lokasi</th>
                                <th>Instansi Pemberi Tugas</th>
                                <th>Alamat</th>
                                <th>Tanggal Kontrak</th>
                                <th>Selesai Kontrak</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Looping data izin_usaha dengan Map */}
                            {currentItems.map(pengalaman =>
                                <tr key={pengalaman.id}>
                                    <td className='textTableWrapperUser'>{pengalaman.nama_kontrak} </td>
                                    <td><p className='textTableWrapperUser'>{pengalaman.lokasi}</p> </td>
                                    <td><p className='textTableWrapperUser'>{pengalaman.instansi} </p></td>
                                    <td className='textTableWrapperUser'>{pengalaman.alamat} </td>
                                    <td>{pengalaman.tanggal_pelaksanaan} </td>
                                    <td>{pengalaman.selesai_kontrak} </td>
                                    <td>
                                        <Link className='text-decoration'
                                            to={
                                                {
                                                    pathname: `/admin/pengalaman/lihat`,
                                                    state: {
                                                        id: pengalaman.id,
                                                    }
                                                }
                                            }>
                                            <Button className='btn btn-tambah'>Lihat</Button>{' '}
                                        </Link>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <ul className='nav justify-content-end pageNumbers'>
                        <li>
                            <button className='btn-pagination' onClick={handlePrevBtn} disabled={currentPage === pages[0] ? true : false}>
                                <i className="fa-solid fa-angle-left"></i>
                            </button>
                        </li>
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}
                        <li>
                            <button className='btn-pagination' onClick={handleNextBtn} disabled={currentPage === pages[pages.length - 1] ? true : false}>
                                <i className="fa-solid fa-angle-right"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
