import React, { useEffect, useState, useContext } from 'react';
import { CustomInput, Form, FormGroup, Label, Alert, Col, Input, Button } from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'
import swal from 'sweetalert'

import axios from 'axios'
import qs from 'querystring'
import MenuComp from '../../MenuComp';
import NavSide from '../../NavSide';
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';

const api = global.api

export default function EditDataPerusahaan(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // Untuk nampung data
    const stateForm = {
        id_company: state.id_user,
        response: '',
        nama_perusahaan: props.location.state.nama_perusahaan,
        email: props.location.state.email,
        npwp: props.location.state.npwp,
        bidang_usaha: props.location.state.bidang_usaha,
        username: props.location.state.username,
        tipe_perusahaan: props.location.state.tipe_perusahaan,
        alamat: props.location.state.alamat,
        kota: props.location.state.kota,
        provinsi: props.location.state.provinsi,
        kode_pos: props.location.state.kode_pos,
        telepon: props.location.state.telepon,
        website: props.location.state.website,
        kantor_cabang: props.location.state.kantor_cabang,
    }
    const [dataform, setDataForm] = useState(stateForm)

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Jika ada perubahan di inputan, yang akan di simpan di dalam state
    const handleInputChange = event => {
        // Ketika form diinputkan data, maka akan merubah data state
        setDataForm({
            ...dataform,
            [event.target.name]: event.target.value
        })
    }

    const ubahDataPerusahaan = (idcompany) => {
        const data = qs.stringify({
            id_company: idcompany,
            nama_perusahaan: dataform.nama_perusahaan,
            email: dataform.email,
            npwp: dataform.npwp,
            bidang_usaha: dataform.bidang_usaha,
            username: dataform.username,
            tipe_perusahaan: dataform.tipe_perusahaan,
            alamat: dataform.alamat,
            kota: dataform.kota,    // Wajib isi
            provinsi: dataform.provinsi,    // Wajib isi
            kode_pos: dataform.kode_pos,
            telepon: dataform.telepon,  // Wajib isi
            website: dataform.website,
            kantor_cabang: dataform.kantor_cabang
        })

        if (dataform.kota.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Kota Belum diisi!!",
                display: 'block'
            })
        } else if (dataform.provinsi.length === 0) {
            setErrform({
                ...errform,
                errormessage: 'Provinsi Belum diisi!!',
                display: 'block'
            })
        } else if (dataform.telepon.length === 0) {
            setErrform({
                ...errform,
                errormessage: 'Telepon Belum diisi!!',
                display: 'block'
            })
        } else {
            swal({
                title: "Apakah anda yakin akan edit data ini?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(api + '/ubah-user-company', data).then(json => {
                        if (json.data.status === 200) {
                            // setDataForm({
                            //     response: json.data.values,
                            // })
                            props.history.push("/data-perusahaan");
                        } else {
                            // setDataForm({
                            //     response: json.data.values,
                            // })
                        }
                    })
                    swal("Data berhasil edit!", {
                        icon: "success",
                    });
                } else {
                    swal("Data gagal edit!");
                }
            });
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-keempat'>
                <Form className='data-perusahaan'>
                    <MenuComp />
                    <FormGroup row >
                        <Label sm={3}>Nama Perusahaan</Label>
                        <Col sm={8}>
                            <Input type="text" name="nama_perusahaan" value={dataform.nama_perusahaan} id="nama_perusahaan" disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Tipe Perusahaan</Label>
                        <Col sm={5} onChange={handleInputChange}>
                            <CustomInput type="radio" id="tipe_perusahaan1" name="tipe_perusahaan" value="PT" label="PT" inline />
                            <CustomInput type="radio" id="tipe_perusahaan2" name="tipe_perusahaan" value="Yayasan" label="Yayasan" inline />
                            <CustomInput type="radio" id="tipe_perusahaan3" name="tipe_perusahaan" value="Koperasi" label="Koperasi" inline />
                            <CustomInput type="radio" id="tipe_perusahaan4" name="tipe_perusahaan" label="CV" value="CV" inline />
                            <CustomInput type="radio" id="tipe_perusahaan5" name="tipe_perusahaan" label="Firma" value="Firma" inline />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={3}>Email</Label>
                        <Col sm={8}>
                            <Input type="email" name="email" id="email" value={dataform.email} disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={3}>NPWP</Label>
                        <Col sm={8}>
                            <Input type="text" name="npwp" id="npwp" value={dataform.npwp} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Alamat Lengkap</Label>
                        <Col sm={8}>
                            <Input type="textarea" name="alamat" value={dataform.alamat} onChange={handleInputChange} id="textAreaa" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Kota<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="kota" id="kota" value={dataform.kota || ''} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Provinsi<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="provinsi" id="provinsi" value={dataform.provinsi || ''} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Kode Pos</Label>
                        <Col sm={8}>
                            <Input type="text" name="kode_pos" value={dataform.kode_pos} onChange={handleInputChange} id="exampleText" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Telephone / HP<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="telepon" id="telepon" value={dataform.telepon || ''} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Website</Label>
                        <Col sm={8}>
                            <Input type="text" name="website" id="website" value={dataform.website} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Kantor Cabang ?</Label>
                        <Col sm={8} onChange={handleInputChange}>
                            <CustomInput type="radio" id="kantor_cabang1" name="kantor_cabang" value="Tidak" label="Tidak" inline />
                            <CustomInput type="radio" id="kantor_cabang2" name="kantor_cabang" value="Ya" label="Ya" inline />
                        </Col>
                    </FormGroup>
                    <div className='bg-orange'>
                        <p><font color="red">*</font>  Data Ini wajib diisi</p>
                    </div>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={() => ubahDataPerusahaan(dataform.id_company)} >Save</Button>{' '}
                        <Link to={'/data-perusahaan'} className='text-decoration'>
                            <Button className='btn btn-edit' >Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
