import React, { useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Nav, Label, Row, Col, Input, Button } from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'
import logo from '../../../Utils/image/logo.png'
import axios from 'axios'
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';
import MenuCompLihat from './MenuCompLihat';

const api = global.api

export default function LihatTenagaAhli(props) {
    const { state, dispatch } = useContext(AuthContext)

    // untuk tampung data tenaga ahli
    const [tenaga_ahli, setTenaga_ahli] = useState([])

    const [dataPengalaman, setDataPengalaman] = useState([])
    const [dataPendidikan, setDataPendidikan] = useState([])
    const [dataSertifikat, setDataSertifikat] = useState([])

    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }
    const fetchData = () => {
        axios.get(api + `/auth/api/v2/company/tampil-tenaga-ahli/${props.location.state.id_user}/${props.location.state.nama}`, config)
            .then(res => {
                setTenaga_ahli(res.data.values)
            })
            .catch(e => {
                // console.log(e)
            })

        axios.get(api + `/auth/api/v1/company/tampil-ta-pengalaman/${props.location.state.id_user}/${props.location.state.nama}`, config)
            .then(res => {
                setDataPengalaman(res.data.values)
            })

        axios.get(api + `/auth/api/v1/company/tampil-ta-pendidikan/${props.location.state.id_user}/${props.location.state.nama}`, config)
            .then(res => {
                setDataPendidikan(res.data.values)
            })

        axios.get(api + `/auth/api/v1/company/tampil-ta-sertifikat/${props.location.state.id_user}/${props.location.state.nama}`, config)
            .then(res => {
                setDataSertifikat(res.data.values)
            })
    }

    const timeOut = () => {
        // Menghitung Mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar terlogout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping otomatis
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek auth
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    if (state.role !== 3) {
        return <Redirect to="/" />
    }


    return (
        <div>
            <div className='nav-sides'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link className='body-btn' to='/admin/data-perusahaan' >
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link className='body-btn' to='/admin/izin-usaha' >
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/admin/akta" className='body-btn' >
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/admin/pemilik" className='body-btn' >
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/admin/pengurus" className='body-btn' >
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/admin/tenaga-ahli" className='body-btn active-background' >
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/admin/pengalaman" className='body-btn' >
                        <p className='textt'>Pengalaman</p>
                    </Link>
                </Nav>
            </div>
            <div className='bg-kesatu'>
                <MenuCompLihat />
                <Form className='data-tenaga-ahli'>
                    {tenaga_ahli.map(tenaga_ahli =>
                        <Row key={tenaga_ahli}>
                            <Col xs="6">
                                <FormGroup row >
                                    <Label sm={5}>Nama</Label>
                                    <Col sm={7}>
                                        <Input type="text" name="nama" defaultValue={tenaga_ahli.nama} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={5}>Tanggal Lahir</Label>
                                    <Col sm={7}>
                                        <Input type="date" name='tanggal_lahir' defaultValue={tenaga_ahli.tanggal_lahir} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row mb={5}>
                                    <Label for="exampleAlamat" sm={5}>Alamat</Label>
                                    <Col sm={7}>
                                        <Input type="textarea" name="alamat" id="textArea" defaultValue={tenaga_ahli.alamat} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={5}>Pendidikan Terakhir</Label>
                                    <Col sm={7}>
                                        <Input type="text" name="pendidikan_terakhir" defaultValue={tenaga_ahli.pendidikan_terakhir} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={5}>Email</Label>
                                    <Col sm={7}>
                                        <Input type="text" name="email" defaultValue={tenaga_ahli.email} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row mb={5}>
                                    <Label sm={5}>Profesi/Keahlian</Label>
                                    <Col sm={7}>
                                        <Input type="textarea" name="profesi_keahlian" id="textArea" defaultValue={tenaga_ahli.profesi_keahlian} readOnly />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup row >
                                    <Label sm={4}>KTP</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="ktp" defaultValue={tenaga_ahli.ktp} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={4}>NPWP</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="npwp" defaultValue={tenaga_ahli.npwp} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4}>Jenis Kelamin</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="jenis_kelamin" defaultValue={tenaga_ahli.jenis_kelamin} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={4}>Kewarganegaraan</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="kewarganegaraan" defaultValue={tenaga_ahli.kewarganegaraan} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={6}>Pengalaman Kerja (Tahun)</Label>
                                    <Col sm={6}>
                                        <Input type="text" name="pengalaman_kerja" defaultValue={tenaga_ahli.pengalaman_kerja} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={5}>Status Kepegawaian</Label>
                                    <Col sm={7} >
                                        <Input type="text" name="status_kepegawaian" defaultValue={tenaga_ahli.status_kepegawaian} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={3}>Jabatan</Label>
                                    <Col sm={9}>
                                        <Input type="text" name="jabatan" defaultValue={tenaga_ahli.jabatan} readOnly />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                </Form>

                <br />
                <br />

                <Form className='data-tenaga-ahli'>
                    <Row>
                        <Col>
                            <h5>Curriculum Vitae (CV)</h5>
                        </Col>
                    </Row>
                    {/* Pengalaman */}
                    <Row>
                        <Col>
                            <p>Pengalaman</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataPengalaman.map(dataPengalaman =>
                                <FormGroup row key={dataPengalaman.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataPengalaman.tahun} disabled />
                                    </Col>
                                    <Col sm={10}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataPengalaman.uraian} disabled />
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <br />
                    {/* Pendidikan */}
                    <Row>
                        <Col>
                            <p>Pendidikan</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataPendidikan.map(dataPendidikan =>
                                <FormGroup row key={dataPendidikan.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataPendidikan.tahun} disabled />
                                    </Col>
                                    <Col sm={10}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataPendidikan.uraian} disabled />
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <br />
                    {/* Sertifikat */}
                    <Row>
                        <Col>
                            <p>Sertifikat</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {dataSertifikat.map(dataSertifikat =>
                                <FormGroup row key={dataSertifikat.id}>
                                    <Col sm={2}>
                                        <Label>Tahun</Label>
                                        <Input type="text" name="tahun" value={dataSertifikat.tahun} disabled />
                                    </Col>
                                    <Col sm={10}>
                                        <Label>Uraian</Label>
                                        <Input type="textarea" name="uraian" value={dataSertifikat.uraian} disabled />
                                    </Col>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                    <div className="d-flex flex-row-reverse">
                        <Link to={'/admin/tenaga-ahli'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
