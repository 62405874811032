import React, { useEffect, useState, useContext } from 'react';
import { Table, Button } from 'reactstrap';
import swal from 'sweetalert';
import { Redirect, Link } from 'react-router-dom';

import axios from 'axios'
import qs from 'querystring'
import NavSide from '../NavSide';
import MenuComp from '../MenuComp';
import { AuthContext } from '../../../App';
import '../../GlobalVariable';
// import ReactPaginate from 'react-paginate';

const api = global.api

export default function Pengalaman(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    const [pengalaman, setPengalaman] = useState([])

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)   // yang ditampilkan per satu halaman
    const [itemsPerPage] = useState(5)  // menampilkan 5 item
    // State untuk tunjukan batas halaman
    const [pageNumberLimit] = useState(4) // menampilkan berapa banyak halaman nomor yang akan ditampilkan
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(4) // Batas nomor halaman maksimum
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    const handleClick = (event) => { // targetnya adalah id yang merupakan nomor
        setCurrentPage(Number(event.target.id))
    }

    const pages = [];   // berisi jumlah total halaman
    // data array akan menggunakan loop, data akan dibagi dengan itemsPerPage
    for (let i = 1; i <= Math.ceil(pengalaman.length / itemsPerPage); i++) {
        pages.push(i);
    }

    // Berapa banyak item yang diinginkan
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFistItem = indexOfLastItem - itemsPerPage;

    // untuk merender nomor halaman
    const renderPageNumbers = pages.map(number => {

        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                // Pada className, jika halaman saat ini sama dengan nomor halaman, maka tambahkan class active, yang lain jangan lakukan apapun
                <li key={number} id={number} onClick={handleClick}
                    className={currentPage === number ? "active" : null}
                >
                    {number}
                </li>
            )
        } else {
            return null;
        }
    })

    // Search Bar
    const [searchTerm, setSearchTerm] = useState('')

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-pengalaman/${state.id_user}`, config)
            .then(res => {
                setPengalaman(res.data.values)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    // Fungsi Hapus Tendaga Ahli berdasarkan id
    const HapusPengalaman = (idpengalaman) => {
        const data = qs.stringify({
            id: idpengalaman
        })

        swal({
            title: "Apakah anda yakin akan menghapus data ini?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(api + '/auth/api/v1/company/hapus-pengalaman', {
                    data: data,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + state.token
                    }
                }).then(json => {
                    if (json.data.status === 200) {
                        fetchData()
                        props.history.push('/pengalaman')
                    }
                })
                swal("Data berhasil dihapus!", {
                    icon: "success",
                });
            } else {
                swal("Data gagal dihapus!");
            }
        });
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, []);

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    // Pagination
    const handleNextBtn = () => {
        setCurrentPage(currentPage + 1)

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }

    const handlePrevBtn = () => {
        setCurrentPage(currentPage - 1)

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    // Untuk memberikan tampilan ketika mempunyai banyak data
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextBtn}> &hellip;</li>
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevBtn}> &hellip;</li>
    }

    const currentItems = pengalaman.filter((pengalaman) => {
        if (searchTerm === "") {
            return pengalaman
        } else if (pengalaman.nama_kontrak.toLowerCase().includes(searchTerm.toLowerCase())) {
            return pengalaman
        }
    }).slice(indexOfFistItem, indexOfLastItem)  // Iris data untuk setiap halaman

    return (
        <div>
            <NavSide />
            <div className='bg-kedua-tambah-height'>
                <MenuComp />
                <div className='bg-ketiga-tambah-height'>
                    <div className='d-flex flex-row-reverse'>
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" onChange={event => { setSearchTerm(event.target.value) }} />
                        </div>
                    </div>

                    <Table>
                        <thead className='table-header'>
                            <tr>
                                <th>Pekerjaan</th>
                                <th>Lokasi</th>
                                <th>Instansi Pemberi Tugas</th>
                                <th>Alamat</th>
                                <th>Tanggal Kontrak</th>
                                <th>Selesai Kontrak</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Looping data izin_usaha dengan Map */}
                            {currentItems.map(pengalaman =>
                                <tr key={pengalaman.id}>
                                    <p className='textTableWrapperUser'></p>
                                    <td><p className='textTableWrapperUser'>{pengalaman.nama_kontrak}</p></td>
                                    <td>
                                        <p className='textTableWrapperUser'>{pengalaman.lokasi}</p></td>
                                    <td><p className='textTableWrapperUser'>{pengalaman.instansi}</p></td>
                                    <td><p className='textTableWrapperUser'>{pengalaman.alamat}</p></td>
                                    <td>{pengalaman.tanggal_pelaksanaan} </td>
                                    <td>{pengalaman.selesai_kontrak} </td>
                                    <td>
                                        <Button className='btn btn-hapus' color='danger' onClick={() => HapusPengalaman(pengalaman.id)}>
                                            <i class="fas fa-trash"></i>  Hapus
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <ul className='nav justify-content-end pageNumbers'>
                        <li>
                            <button className='btn-pagination' onClick={handlePrevBtn} disabled={currentPage === pages[0] ? true : false}>
                                <i className="fa-solid fa-angle-left"></i>
                            </button>
                        </li>
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}
                        <li>
                            <button className='btn-pagination' onClick={handleNextBtn} disabled={currentPage === pages[pages.length - 1] ? true : false}>
                                <i className="fa-solid fa-angle-right"></i>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="d-flex flex-row-reverse">
                    <Link className='text-decoration' to='/pengalaman/tambah'>
                        <Button className='btn btn-tambah'>Tambah</Button>{' '}
                    </Link>
                </div>
            </div>
        </div>
    )
}
