import React, { useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Label, Row, Col, Input, Button, CustomInput, Alert } from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'

import axios from 'axios'
import NavSide from '../../NavSide';
import MenuComp from '../../MenuComp';
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';
import { DataContext } from '../../../../DataContext';

const api = global.api

export default function TambahTenagaAhli(props) {
    const { state, dispatch } = useContext(AuthContext)

    const [tenaga_ahli, setTenaga_ahli] = useState([])

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-tenaga-ahli/${state.id_user}`, config)
            .then(res => {
                setTenaga_ahli({
                    tenaga_ahlis: res.data.values,
                })
            })
            .catch(e => {
                // console.log(e)
            })
    }

    // Membaca data dari app js ( lihatData )
    let { taNama, setTanama } = useContext(DataContext)

    // Untuk tambah data tenaga_ahli
    const stateForm = {
        id_user: state.id_user,
        role: state.role,
        nama: '',   // Wajib diisi
        alamat: '', // Wajib diisi
        tanggal_lahir: '',  // Wajib diisi
        pendidikan_terakhir: '',    // Wajib diisi
        email: '',
        profesi_keahlian: '',   // Wajib diisi
        ktp: '',   // Wajib diisi
        npwp: '',
        jenis_kelamin: '',  // Wajib diisi
        kewarganegaraan: '',
        pengalaman_kerja: '',   // Wajib diisi
        status_kepegawaian: '', // Wajib diisi
        jabatan: '',
    }
    const [dataForm, setDataform] = useState(stateForm)
    // Untuk menampilkan error jika data belum diisi
    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    const handleInputChange = event => {
        setDataform({
            ...dataForm,
            [event.target.name]: event.target.value
        })
    }

    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }

    // Fungsi Tambah data
    const AddTenagaAhli = () => {
        // if (res.data.success === false && res.data.isRegistered === true) {
        //     setData({
        //         ...data,
        //         // Kalau Error ganti Message jadi message
        //         errorMessage: "Email anda belum terverifikasi, silahkan cek email atau spam untuk verifikasi."
        //     })
        // }
        if (dataForm.nama.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nama belum diisi",
                display: 'block',
            })
        } else if (dataForm.tanggal_lahir.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Tanggal Lahir belum diisi",
                display: 'block',
            })
        } else if (dataForm.alamat.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Alamat belum diisi",
                display: 'block',
            })
        } else if (dataForm.pendidikan_terakhir.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Pendidikan Terakhir belum diisi",
                display: 'block',
            })
        } else if (dataForm.profesi_keahlian.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Profesi Keahlian belum diisi",
                display: 'block',
            })
        } else if (dataForm.ktp.length === 0) {
            setErrform({
                ...errform,
                errormessage: "KTP belum diisi",
                display: 'block',
            })
        } else if (dataForm.jenis_kelamin.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Jenis Kelamin belum diisi",
                display: 'block',
            })
        } else if (dataForm.pengalaman_kerja.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Pengalaman Kerja belum diisi",
                display: 'block',
            })
        } else if (dataForm.status_kepegawaian.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Status Kepegawaian belum diisi",
                display: 'block',
            })
        } else {
            axios.post(api + '/auth/api/v1/company/tambah-tenaga-ahli', {
                id_user: dataForm.id_user,
                role: dataForm.role,
                nama: dataForm.nama,
                alamat: dataForm.alamat,
                jabatan: dataForm.jabatan,
                tanggal_lahir: dataForm.tanggal_lahir,
                pendidikan_terakhir: dataForm.pendidikan_terakhir,
                email: dataForm.email,
                profesi_keahlian: dataForm.profesi_keahlian,
                ktp: dataForm.ktp,
                npwp: dataForm.npwp,
                jenis_kelamin: dataForm.jenis_kelamin,
                kewarganegaraan: dataForm.kewarganegaraan,
                pengalaman_kerja: dataForm.pengalaman_kerja,
                status_kepegawaian: dataForm.status_kepegawaian,
            }, config)
                .then(json => {
                    if (json.data.values === "KTP Sudah terdaftar") {
                        setErrform({
                            ...errform,
                            errormessage: "KTP Sudah Terdaftar",
                            display: 'block',
                        })
                    } else if (json.data.values === "Data tenaga ahli cek berhasil ditambahkan") {
                        setTanama(
                            taNama = dataForm.nama
                        )
                        props.history.push("/pengalaman/tambah/cv");
                    } 
                })
        }
    }

    const timeOut = () => {
        // Menghitung Mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar terlogout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping otomatis
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek auth
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                <Form className='data-tenaga-ahli'>
                    <Row>
                        <Col xs="6">
                            <FormGroup row >
                                <Label sm={5}>Nama<font color="red"> *</font></Label>
                                <Col sm={7}>
                                    <Input type="text" name="nama" value={dataForm.nama} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={5}>Tanggal Lahir<font color="red"> *</font></Label>
                                <Col sm={7}>
                                    <Input type="date" name='tanggal_lahir' value={dataForm.tanggal_lahir} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row mb={5}>
                                <Label for="exampleAlamat" sm={5}>Alamat<font color="red"> *</font></Label>
                                <Col sm={7}>
                                    <Input type="textarea" name="alamat" value={dataForm.alamat} onChange={handleInputChange} id="textArea" />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={5}>Pendidikan Terakhir<font color="red"> *</font></Label>
                                <Col sm={7}>
                                    <Input type="text" name="pendidikan_terakhir" value={dataForm.pendidikan_terakhir} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={5}>Email</Label>
                                <Col sm={7}>
                                    <Input type="text" name="email" value={dataForm.email} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row mb={5}>
                                <Label sm={5}>Profesi/Keahlian<font color="red"> *</font></Label>
                                <Col sm={7}>
                                    <Input type="textarea" name="profesi_keahlian" value={dataForm.profesi_keahlian} onChange={handleInputChange} id="textArea" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <FormGroup row >
                                <Label sm={4}>KTP<font color="red"> *</font></Label>
                                <Col sm={8}>
                                    <Input type="text" name="ktp" value={dataForm.ktp} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={4}>NPWP</Label>
                                <Col sm={8}>
                                    <Input type="text" name="npwp" value={dataForm.npwp} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Jenis Kelamin<font color="red"> *</font></Label>
                                <Col sm={8} onChange={handleInputChange}>
                                    <CustomInput type="radio" id="exampleCustomRadio1" name="jenis_kelamin" value="Pria" label="Pria" inline />
                                    <CustomInput type="radio" id="exampleCustomRadio2" name="jenis_kelamin" value="Wanita" label="Wanita" inline />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={4}>Kewarganegaraan</Label>
                                <Col sm={8}>
                                    <Input type="text" name="kewarganegaraan" value={dataForm.kewarganegaraan} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={6}>Pengalaman Kerja (Tahun)<font color="red"> *</font></Label>
                                <Col sm={6}>
                                    <Input type="text" name="pengalaman_kerja" value={dataForm.pengalaman_kerja} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Status Kepegawaian<font color="red"> *</font></Label>
                                <Col sm={7} onChange={handleInputChange}>
                                    <CustomInput type="radio" id="exampleCustomRadio6" name="status_kepegawaian" value="Tetap" label="Tetap" inline />
                                    <CustomInput type="radio" id="exampleCustomRadio7" name="status_kepegawaian" value="Tidak Tetap" label="Tidak Tetap" inline />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Label sm={3}>Jabatan</Label>
                                <Col sm={9}>
                                    <Input type="text" name="jabatan" value={dataForm.jabatan} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={AddTenagaAhli}>Selanjutnya</Button>{' '}
                        <Link to={'/tenaga-ahli'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
