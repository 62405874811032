import React from 'react'
import { NavbarText} from 'reactstrap'
import { Link } from 'react-router-dom';
import '../../CSS/Menu-Comp.css';

function MenuCompLihat() {
    return (
        <div className='d-flex flex-row-reverse menu-comp'>
            <NavbarText>
                <Link className='' to='/admin/daftar-pengajuan'>
                    <span>Kembali</span>
                </Link>
            </NavbarText>
        </div>
    )
}

export default MenuCompLihat