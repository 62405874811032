import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../App';
// import '../CSS/index.css'
import { Form, FormGroup, Label, Alert, Col, Input, Button } from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'
import swal from 'sweetalert';

import axios from 'axios'
import NavSide from '../../NavSide';
import MenuComp from '../../MenuComp';
import '../../../GlobalVariable'

const api = global.api

export default function TambahPengurus(props) {
    const { state, dispatch } = useContext(AuthContext)

    const stateForm = {
        response: '',
        display: 'none',
        id_user: state.id_user,
        role: state.role,
        nama_pengurus: '',
        ktp_pengurus: '',
        alamat_pengurus: '',
        jabatan_pengurus: '',
        tanggal_mulai: '',
        tanggal_selesai: '',
    }

    const [dataForm, setDataform] = useState(stateForm)

    const handleInputChange = event => {
        setDataform({
            ...dataForm,
            [event.target.name]: event.target.value
        })
    }

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Tambah data
    const AddPengurus = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        if (dataForm.nama_pengurus.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nama belum diisi!!",
                display: 'block'
            })
        } else if (dataForm.ktp_pengurus.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nomor KTP belum diisi!!",
                display: 'block'
            })
        } else if (dataForm.alamat_pengurus.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Alamat belum diisi!!",
                display: 'blcok'
            })
        } else if (dataForm.jabatan_pengurus.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Jabatan belum diisi!!",
                display: 'block'
            })
        } else if (dataForm.tanggal_mulai.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Menjabat Sejak belum diisi!!",
                display: 'block'
            })
        } else if (dataForm.tanggal_selesai.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Sampai belum diisi!!",
                display: 'block'
            })
        } else {
            swal({
                title: "Data berhasil ditambah!",
                text: "",
                icon: "success",
                button: "Back",
            }).then(
                axios.post(api + '/auth/api/v1/company/tambah-pengurus', {
                    id_user: dataForm.id_user,
                    nama_pengurus: dataForm.nama_pengurus,
                    ktp_pengurus: dataForm.ktp_pengurus,
                    alamat_pengurus: dataForm.alamat_pengurus,
                    jabatan_pengurus: dataForm.jabatan_pengurus,
                    tanggal_mulai: dataForm.tanggal_mulai,
                    tanggal_selesai: dataForm.tanggal_selesai,
                    role: dataForm.role
                }, config).then(json => {
                    if (json.data.status === 200) {
                        props.history.push("/pengurus");
                    }
                })
            )
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                <Alert color='success' style={{ display: dataForm.display }}>
                    {dataForm.response}
                </Alert>
                <Form className='data-perusahaan'>
                    <FormGroup row >
                        <Label sm={2}>Nama<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="nama_pengurus" value={dataForm.nama_pengurus} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={2}>Nomor KTP<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="ktp_pengurus" value={dataForm.ktp_pengurus} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={2}>Alamat<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="alamat_pengurus" value={dataForm.alamat_pengurus} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={2}>Jabatan<font color="red"> *</font></Label>
                        <Col sm={8}>
                            <Input type="text" name="jabatan_pengurus" value={dataForm.jabatan_pengurus} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row >
                        <Label sm={2}>Menjabat Sejak<font color="red"> *</font></Label>
                        <Col sm={3}>
                            <Input type="date" name='tanggal_mulai' value={dataForm.tanggal_mulai} onChange={handleInputChange} />
                        </Col>
                        <Label sm={1}>Sampai<font color="red"> **</font></Label>
                        <Col sm={3}>
                            <Input type="date" name='tanggal_selesai' value={dataForm.tanggal_selesai} onChange={handleInputChange} />
                        </Col>
                    </FormGroup>
                    <div className='bg-orange'>
                        <p className='c'><font color="red">*</font>  Data Ini wajib diisi</p>
                        <p><font color="red">** </font>Isi dengan tanggal terakhir menjadi Pengurus Perusahaan. Kosongkan jika masih menjabat</p>
                    </div>
                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>
                    <div className="d-flex flex-row-reverse">
                        <Button type='button' className='btn btn-save' onClick={AddPengurus} >Save</Button>{' '}
                        <Link to={'/pengurus'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}
