import React, { useContext, useState, useEffect } from 'react';
import { Form, FormGroup, Nav, Label, Row, Col, Input, Button } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import logo from '../../../Utils/image/logo.png';
import axios from 'axios';
import '../../../GlobalVariable'
import { AuthContext } from '../../../../App';
import MenuCompLihat from './MenuCompLihat';

const api = global.api

export default function LihatPengalaman(props) {
    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)

    // untuk tampung data dari fungsi fetch
    const [pengalaman, setPengalaman] = useState([])

    var config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.token
        }
    }

    const fetchData = () => {
        axios.get(api + `/auth/api/v2/company/tampil-pengalaman/${props.location.state.id}`, config)
            .then(res => {
                setPengalaman(res.data.values)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint berfungsi untuk melooping baris
        fetchData()
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <div className='nav-sides'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link className='body-btn' to='/admin/data-perusahaan' >
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link className='body-btn' to='/admin/izin-usaha' >
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/admin/akta" className='body-btn' >
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/admin/pemilik" className='body-btn' >
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/admin/pengurus" className='body-btn' >
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/admin/tenaga-ahli" className='body-btn' >
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/admin/pengalaman" className='body-btn active-background' >
                        <p className='textt'>Pengalaman</p>
                    </Link>
                </Nav>
            </div>
            <div className='bg-tambah-tenaga'>
                <MenuCompLihat />
                {pengalaman.map(pengalaman =>
                    <Form className='data-tenaga-ahli' key={pengalaman}>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label sm={2} className='box-width-pengalaman'>Nama Kontrak</Label>
                                    <Col sm={10} >
                                        <Input type="textarea" name="nama_kontrak" value={pengalaman.nama_kontrak} readOnly id="textArea" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={2}>Lokasi</Label>
                                    <Col sm={10}>
                                        <Input type="text" name="lokasi" value={pengalaman.lokasi} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={2}>Instansi</Label>
                                    <Col sm={10}>
                                        <Input type="text" name="instansi" value={pengalaman.instansi} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={2}>Alamat</Label>
                                    <Col sm={10}>
                                        <Input type="text" name="alamat" value={pengalaman.alamat} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={2}>No Telp</Label>
                                    <Col sm={10}>
                                        <Input type="text" name="no_telepon" value={pengalaman.no_telepon} readOnly />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <FormGroup row >
                                    <Label sm={4} >No Kontrak</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="no_kontrak" value={pengalaman.no_kontrak} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={4}>Tanggal Pelaksanaan</Label>
                                    <Col sm={8}>
                                        <Input type="date" name='tanggal_pelaksanaan' value={pengalaman.tanggal_pelaksanaan} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={4}>Selesai Kontrak</Label>
                                    <Col sm={8}>
                                        <Input type="date" name='selesai_kontrak' value={pengalaman.selesai_kontrak} readOnly />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col xs="6">
                                <FormGroup row >
                                    <Label sm={3}>Nilai Kontrak</Label>
                                    <Col sm={9}>
                                        <Input type="text" name="nilai_kontrak" value={pengalaman.nilai_kontrak} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label sm={3}>Presentase Pelaksanaan</Label>
                                    <Col sm={9}>
                                        <Input type="text" name="presentase_pelaksanaan" value={pengalaman.presentase_pelaksanaan} readOnly />
                                    </Col>
                                </FormGroup>
                                <FormGroup row >
                                    <Label for="exampleName" sm={3}>Tgl Serah terima</Label>
                                    <Col sm={9}>
                                        <Input type="date" name='tgl_serah_terima' value={pengalaman.tgl_serah_terima} readOnly />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label sm={2}>Keterangan</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="keterangan" value={pengalaman.keterangan} readOnly id="textArea" />
                            </Col>
                        </FormGroup>
                        <div className="d-flex flex-row-reverse">
                            <Link to={'/admin/pengalaman'} className='text-decoration'>
                                <Button className='btn btn-edit'>Back</Button>{' '}
                            </Link>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    )
}
