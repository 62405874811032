import React from "react";
import logo_verifikasi from "../Utils/image/verify.png"
import icon_to_login from "../Utils/icon/back_to_login.png"
import { Link } from "react-router-dom";

const VerifikasiBerhasil = () => {
    return (
        <div className="ParentBox">
            <div className="container">
                <div className="verifikasi-email">
                    <img src={logo_verifikasi} alt="..."></img>
                    <p> <b>ANDA TELAH TERVERIFIKASI</b></p>
                    <p className="txt-verif"> <i>YOU HAVE BEEN VERIFICATED</i></p>
                    <Link to='/' className="d-flex flex-row-reverse dec-none">
                        <div className="back-login">
                            <img src={icon_to_login} alt="Icon Kembali" />
                            <p>Login</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default VerifikasiBerhasil;