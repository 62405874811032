import { Redirect, Link } from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react'
import { Input, Form, FormGroup, Label, Col, Button, Alert } from 'reactstrap'
import swal from 'sweetalert';
import axios from 'axios'
import qs from 'querystring'
import NavSide from '../../NavSide';
import MenuComp from '../../MenuComp';
import { AuthContext } from '../../../../App';
import '../../../GlobalVariable'

const api = global.api

export default function EditAkta(props) {
    // Memabaca data global dari app js (LOGIN)
    const { state, dispatch } = useContext(AuthContext)

    // Fungsi dari stateForm
    const stateForm = {
        id_akta: props.location.state.id_akta,
        id_user: state.id_user,
        nomor: props.location.state.nomor ,
        tanggal_surat: props.location.state.tanggal_surat,
        notaris: props.location.state.notaris,
        no_perubahan: props.location.state.no_perubahaan,
        tgl_perubahan: props.location.state.tgl_perubahan,
        notaris_perubahan: props.location.state.notaris_perubahan,
    }
    const [dataform, setDataForm] = useState(stateForm)

    const errForm = {
        errormessage: '',
        display: 'none',
    }
    const [errform, setErrform] = useState(errForm)

    // Fungsi Jika ada perubahan di inputan, yang akan di simpan di dalam state
    const handleInputChange = event => {
        // Ketika form diinputkan data, maka akan merubah data state
        setDataForm({
            ...dataform,
            [event.target.name]: event.target.value
        })
    }

    // Fungsi Edit
    const ProsesAkta = (idakta) => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        const data = qs.stringify({
            id_akta: idakta
        })

        if (dataform.nomor.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Nomor belum diisi!!",
                display: 'block'
            })
        } else if (dataform.tanggal_surat.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Tanggal Surat belum diisi!!",
                display: 'block'
            })
        } else if (dataform.notaris.length === 0) {
            setErrform({
                ...errform,
                errormessage: "Notaris belum diisi!!",
                display: 'block'
            })
        } else {
            swal({
                title: "Apakah anda yakin akan edit data ini?",
                text: "",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    axios.post(api + '/auth/api/v1/company/ubah-akta', {
                        data: data,
                        id_akta: dataform.id_akta,
                        nomor: dataform.nomor,
                        tanggal_surat: dataform.tanggal_surat,
                        notaris: dataform.notaris,
                        no_perubahan: dataform.no_perubahan,
                        tgl_perubahan: dataform.tgl_perubahan,
                        notaris_perubahan: dataform.notaris_perubahan,
                    }, config).then(json => {
                        if (json.data.status === 200) {
                            setErrform({
                                ...errform,
                                errormessage: "",
                                display: 'none'
                            })
                            props.history.push("/akta");
                        }
                    })
                    swal("Data berhasil edit!", {
                        icon: "success",
                    });
                } else {
                    swal("Data gagal edit!");
                }
            });
        }
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <NavSide />
            <div className='bg-kedua'>
                <MenuComp />
                <div className='data-akta'>
                    <div className='input-akta'>
                        <h5 className='judul-akta'>Akta Pendirian</h5>
                        <Form>
                            <FormGroup row>
                                <Label for="nomor" sm={3}>Nomor<font color="red"> *</font></Label>
                                <Col sm={6}>
                                    <Input type='text' name='nomor' value={dataform.nomor} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="tanggal_surat" sm={3}>Tanggal Surat<font color="red"> *</font></Label>
                                <Col sm={3}>
                                    <Input type="date" value={dataform.tanggal_surat} name='tanggal_surat' onChange={handleInputChange} id="textAreaa" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="notaris" sm={3}>Notaris<font color="red"> *</font></Label>
                                <Col sm={6}>
                                    <Input type='text' name='notaris' value={dataform.notaris} onChange={handleInputChange} />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>

                    <Alert color='danger' style={{ display: errform.display }}>
                        {errform.errormessage || ''}
                    </Alert>

                    <div className='hasil-akta'>
                        <h5 className='judul-akta'>Akta Perubahan Terakhir</h5>
                        <FormGroup row>
                            <Label for="nomor" sm={3}>Nomor</Label>
                            <Col sm={6}>
                                <Input type='text' name='no_perubahan' value={dataform.no_perubahan} onChange={handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="tanggal_surat" sm={3}>Tanggal Surat</Label>
                            <Col sm={3}>
                                <Input type="date" value={dataform.tgl_perubahan} name='tgl_perubahan' placeholder='2022-03-02' onChange={handleInputChange} id="textAreaa" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="notaris" sm={3}>Notaris</Label>
                            <Col sm={6}>
                                <Input type='text' name='notaris_perubahan' value={dataform.notaris_perubahan} onChange={handleInputChange} />
                            </Col>
                        </FormGroup>
                    </div>
                    <div className='bg-orange'>
                        <p><font color="red">*</font>  Data Ini wajib diisi</p>
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <Button className='btn btn-save' onClick={() => ProsesAkta()}>Save</Button>{' '}
                        <Link to={'/akta'} className='text-decoration'>
                            <Button className='btn btn-edit'>Back</Button>{' '}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
