import React, { useEffect, useState, useContext } from 'react'
import { Form, FormGroup, Label, Col, Input, Nav } from 'reactstrap'
import axios from 'axios'
import { AuthContext } from '../../../../App'
import { Redirect, Link } from 'react-router-dom'
import '../../../GlobalVariable'
import logo from '../../../Utils/image/logo.png'
import '../../CSS/NavSide.css'
import { DataContext } from '../../../../DataContext'
import MenuCompLihat from './MenuCompLihat'

const api = global.api

export default function TampilDataPerusahaanAdmin(props) {
    const [user_company, setuser_company] = useState([])

    // Membaca data global dari app js
    const { state, dispatch } = useContext(AuthContext)
    let { lihatData } = useContext(DataContext)

    const fetchData = () => {
        var config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.token
            }
        }

        axios.get(api + `/auth/api/v1/company/tampil-user-company/${lihatData}`, config)
            .then(res => {
                setuser_company(res.data.values)
            })
            .catch(e => {
                // console.log(e)
            })
    }

    const timeOut = () => {
        // Menghitung mundur
        setTimeout(() => {
            // console.log("Token telah berakhir")
            dispatch({
                type: "LOGOUT"
            })
        }, state.tokenExpires)
    }

    // Agar ter Logout otomatis
    useEffect(() => {
        fetchData() // eslint berfungsi untuk melooping baris
        // eslint-disable-next-line
        timeOut()
        // eslint-disable-next-line
    }, [])

    // Cek autentikasi
    if (!state.isAuthenticated) {
        return <Redirect to="/" />
    }
    
    if (state.role !== 3) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <div className='nav-sides'>
                <Nav
                    className="me-auto"
                    navbar>
                    <Link to="/admin/daftar-pengajuan" className='link-footer'>
                        <img src={logo} className='logo-footer' alt='logo indah karya' />
                        <p className='text-footer'>E-Proc IK</p>
                    </Link>
                    <Link className='body-btn active-background' to='/admin/data-perusahaan'>
                        <p className='textt'>Data Perusahaan</p>
                    </Link>
                    <Link className='body-btn' to='/admin/izin-usaha' >
                        <p className='textt'>Izin Usaha</p>
                    </Link>
                    <Link to="/admin/akta" className='body-btn' >
                        <p className='textt'>Akta</p>
                    </Link>
                    <Link to="/admin/pemilik" className='body-btn' >
                        <p className='textt'>Pemilik</p>
                    </Link>
                    <Link to="/admin/pengurus" className='body-btn' >
                        <p className='textt'>Pengurus</p>
                    </Link>
                    <Link to="/admin/tenaga-ahli" className='body-btn' >
                        <p className='textt'>Tenaga Ahli</p>
                    </Link>
                    <Link to="/admin/pengalaman" className='body-btn' >
                        <p className='textt'>Pengalaman</p>
                    </Link>
                </Nav>
            </div>
            <div className='bg-kedua'>
                {user_company.map(user_company =>
                    <Form className='data-perusahaan' key={user_company}>
                        <MenuCompLihat />
                        <FormGroup row >
                            <Label for="exampleName" sm={3}>Nama Perusahaan</Label>
                            <Col sm={8}>
                                <Input type="text" name="email" id="exampleText" placeholder={user_company.nama_perusahaan} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="exampleName" sm={3}>Tipe Perusahaan</Label>
                            <Col sm={8}>
                                <Input type="text" name="tipe_perusahaan" id="exampleText" placeholder={user_company.tipe_perusahaan} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Label for="exampleEmail" sm={3}>Email</Label>
                            <Col sm={8}>
                                <Input type="email" name="email" id="exampleEmail" placeholder={user_company.email} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Label for="exampleNPWP" sm={3}>NPWP</Label>
                            <Col sm={8}>
                                <Input type="text" name="npwp" id="exampleText" placeholder={user_company.npwp} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="exampleAlamat" sm={3}>Alamat Lengkap</Label>
                            <Col sm={8}>
                                <Input type="textarea" name="alamat" id='textAreaa' placeholder={user_company.alamat} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kota" sm={3}>Kota</Label>
                            <Col sm={8}>
                                <Input type="text" name="kota" id="exampleText" placeholder={user_company.kota} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kota" sm={3}>Provinsi</Label>
                            <Col sm={8}>
                                <Input type="text" name="provinsi" id="exampleText" placeholder={user_company.provinsi} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kota" sm={3}>Kode Pos</Label>
                            <Col sm={8}>
                                <Input type="text" name="kode_pos" id="exampleText" placeholder={user_company.kode_pos} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kota" sm={3}>Telephone / HP</Label>
                            <Col sm={8}>
                                <Input type="text" name="telepon" id="exampleText" placeholder={user_company.telepon} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="Kota" sm={3}>Website</Label>
                            <Col sm={8}>
                                <Input type="text" name="kota" id="exampleText" placeholder={user_company.website} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="exampleName" sm={3}>Kantor Cabang</Label>
                            <Col sm={8}>
                                <Input type="text" name="kantor_cabang" id="exampleText" placeholder={user_company.kantor_cabang} disabled />
                            </Col>
                        </FormGroup>
                    </Form>
                )}
            </div>
        </div>
    )
}
